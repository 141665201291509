import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Upload,
  Image,
  Popconfirm, // Add this import
} from "antd";
import Title from "antd/lib/typography/Title";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import {
  getAuctionProducts,
  createAuctionProduct,
  updateAuctionProduct,
  clearCurrentProduct,
  getAuctionProductById,
  getAuctionProductManage,
  getAuctionWinner,
  clearWinner,
  deleteAuctionProduct, // Add this import
} from "../../features/auction/auction";
import type { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import "./Auction.css";
import { Helmet } from "react-helmet";
import Notification from "../../components/notification/notification";
import { PlusOutlined } from "@ant-design/icons";

// Add these interfaces to match the C# DTOs
interface AuctionDescriptionCommandDto {
  name: string;
  description: string;
  language: string;
}

interface AuctionCommandDto {
  sponsor: string;
  startingBid: number;
  biddingStartDate: string;
  biddingEndDate: string;
  accessLevel: string;
  descriptions: AuctionDescriptionCommandDto[];
  images: string[];
  sponsorLink?: string;
}

interface AuctionUpdateCommandDto extends AuctionCommandDto {
  id: string;
}

const Auction = () => {
  const dispatch = useAppDispatch();
  const { products, currentProduct, isLoading, winner } = useAppSelector(
    (state) => state.auction
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingProduct, setEditingProduct] = useState<any>(null);
  const [notification, setNotification] = useState({
    open: false,
    status: "",
    message: "",
  });
  const [imageList, setImageList] = useState<string[]>([]);
  const [winnerModalVisible, setWinnerModalVisible] = useState(false);

  useEffect(() => {
    dispatch(getAuctionProducts());
  }, [dispatch]);

  useEffect(() => {
    if (currentProduct && editingProduct) {
      // Transform descriptions array into object structure for form
      const descriptionsObj = currentProduct.descriptions.reduce(
        (acc, desc) => ({
          ...acc,
          [desc.language]: {
            name: desc.name,
            description: desc.description,
          },
        }),
        {}
      );

      form.setFieldsValue({
        sponsor: currentProduct.sponsor,
        startingBid: currentProduct.startingBid,
        biddingPeriod: [
          moment(currentProduct.biddingStartDate),
          moment(currentProduct.biddingEndDate),
        ],
        accessLevel: currentProduct.accessLevel,
        descriptions: descriptionsObj,
        sponsorLink: currentProduct.sponsorLink,
      });
      setImageList(currentProduct.images || []);
    }
  }, [currentProduct, form, editingProduct]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Starting Bid",
      dataIndex: "startingBid",
      key: "startingBid",
      render: (value: number) => `${value.toLocaleString()}`,
    },
    {
      title: "Current Bid",
      dataIndex: "currentBid",
      key: "currentBid",
      render: (value: number) => `${value.toLocaleString()}`,
    },
    {
      title: "Bidding Period",
      key: "biddingPeriod",
      render: (_, record: any) =>
        `${moment(record.biddingStart).format("MM/DD/YYYY HH:mm")} - ${moment(
          record.biddingEnd
        ).format("MM/DD/YYYY HH:mm")}`,
    },
    {
      title: "Status",
      key: "status",
      render: (_, record: any) => {
        const now = moment();
        const start = moment(record.biddingStart);
        if (start.isAfter(now)) {
          return "Upcoming";
        }
        return record.hasEnded ? "Ended" : "Ongoing";
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => {
        const now = moment();
        const start = moment(record.biddingStart);
        const isUpcoming = start.isAfter(now);

        return (
          <Space>
            <Button onClick={() => handleEdit(record)}>Edit</Button>
            {/* {isUpcoming ? (
              <Button onClick={() => handleEdit(record)}>Edit</Button>
            ) : (
              <Button onClick={() => handleView(record)}>View</Button>
            )} */}
            {record.hasEnded && (
              <Button onClick={() => showWinnerModal(record.id)}>
                Show Winner
              </Button>
            )}
            {isUpcoming && (
              <Popconfirm
                title="Are you sure you want to delete this product?"
                onConfirm={() => handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>Delete</Button>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const handleView = async (record: any) => {
    try {
      setEditingProduct(record);
      await dispatch(getAuctionProductManage(record.id)).unwrap();
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error fetching auction product details:", error);
    }
  };

  const handleEdit = async (record: any) => {
    try {
      const now = moment();
      const start = moment(record.biddingStart);

      // if (!start.isAfter(now)) {
      //   setNotification({
      //     open: true,
      //     status: "ERROR",
      //     message: "Only upcoming auctions can be edited",
      //   });
      //   return;
      // }

      setEditingProduct(record);
      await dispatch(getAuctionProductManage(record.id)).unwrap();
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error fetching auction product details:", error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await dispatch(deleteAuctionProduct(id)).unwrap();
      setNotification({
        open: true,
        status: "SUCCESS",
        message: "Auction product deleted successfully",
      });
      dispatch(getAuctionProducts());
    } catch (error: any) {
      setNotification({
        open: true,
        status: "ERROR",
        message: error.message || "Failed to delete auction product",
      });
    }
  };

  const handleImageUpload = (file: File) => {
    return new Promise((resolve, reject) => {
      // Check file size (1MB = 1024 * 1024 bytes)
      if (file.size > 1024 * 1024) {
        setNotification({
          open: true,
          status: "ERROR",
          message: "Image size must be less than 1MB",
        });
        reject(new Error("Image too large"));
        return;
      }

      // Check maximum image count
      if (imageList.length >= 3) {
        setNotification({
          open: true,
          status: "ERROR",
          message: "Maximum 3 images allowed",
        });
        reject(new Error("Too many images"));
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === "string") {
          setImageList([...imageList, reader.result]);
          resolve(true);
        } else {
          reject(new Error("Failed to convert image"));
        }
      };
      reader.onerror = () => reject(new Error("Failed to read file"));
      reader.readAsDataURL(file);
    });
  };

  const handleImageRemove = (index: number) => {
    setImageList(imageList.filter((_, i) => i !== index));
  };

  const handleSubmit = async (values: any) => {
    try {
      if (imageList.length === 0) {
        setNotification({
          open: true,
          status: "ERROR",
          message: "At least one image is required",
        });
        return;
      }

      // Transform descriptions from object to array format
      const descriptions = Object.entries(values.descriptions || {}).map(
        ([language, data]: [string, any]) => ({
          name: data.name,
          description: data.description,
          language: language,
        })
      );

      const formattedData: AuctionCommandDto = {
        sponsor: values.sponsor,
        startingBid: Number(values.startingBid),
        biddingStartDate: values.biddingPeriod[0].utc().toISOString(),
        biddingEndDate: values.biddingPeriod[1].utc().toISOString(),
        accessLevel: values.accessLevel,
        descriptions: descriptions,
        images: imageList,
        sponsorLink: values.sponsorLink,
      };

      if (editingProduct) {
        await dispatch(
          updateAuctionProduct({
            id: editingProduct.id,
            data: {
              ...formattedData,
              id: editingProduct.id,
            } as AuctionUpdateCommandDto,
          })
        ).unwrap();

        setNotification({
          open: true,
          status: "SUCCESS",
          message: "Auction product updated successfully",
        });
      } else {
        await dispatch(createAuctionProduct(formattedData)).unwrap();
        setNotification({
          open: true,
          status: "SUCCESS",
          message: "Auction product created successfully",
        });
      }
      dispatch(getAuctionProducts());
      setIsModalVisible(false);
      form.resetFields();
      setEditingProduct(null);
      setImageList([]);
    } catch (error: any) {
      console.log(error);
      setNotification({
        open: true,
        status: "ERROR",
        message:
          error.message || "An error occurred while processing your request",
      });
    }
  };

  const isViewOnly = () => {
    // products for auction is always editable
    return false;
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    form.resetFields();
    setEditingProduct(null);
    setImageList([]);
    dispatch(clearCurrentProduct());
  };

  const showWinnerModal = async (productId: string) => {
    try {
      await dispatch(getAuctionWinner(productId)).unwrap();
      setWinnerModalVisible(true);
    } catch (error: any) {
      setNotification({
        open: true,
        status: "ERROR",
        message: error.message || "Failed to fetch winner details",
      });
    }
  };

  const handleWinnerModalClose = () => {
    setWinnerModalVisible(false);
    dispatch(clearWinner());
  };

  return (
    <>
      <Helmet>
        <title>Auction Management</title>
        <meta name="description" content="Auction Management" />
      </Helmet>
      <div className="auction-container">
        <Row style={{ justifyContent: "space-between", marginBottom: 16 }}>
          <Title level={2}>Auction Management</Title>
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            Add Product
          </Button>
        </Row>
        <Row>
          <Col span={24}>
            <Table columns={columns} dataSource={products} rowKey="id" />
          </Col>
        </Row>
      </div>

      <Modal
        title={
          editingProduct
            ? isViewOnly()
              ? "View Product"
              : "Edit Product"
            : "Add Product"
        }
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width={800}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Tabs defaultActiveKey="en">
            <Tabs.TabPane tab="English" key="en">
              <Form.Item
                name={["descriptions", "en", "name"]}
                rules={[{ required: true }]}
                label="Name"
              >
                <Input placeholder="Product Name" disabled={isViewOnly()} />
              </Form.Item>

              <Form.Item
                name={["descriptions", "en", "description"]}
                rules={[{ required: true }]}
                label="Description"
              >
                <Input.TextArea
                  placeholder="Product Description"
                  disabled={isViewOnly()}
                />
              </Form.Item>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Danish" key="da">
              <Form.Item
                name={["descriptions", "da", "name"]}
                rules={[{ required: true }]}
                label="Name"
              >
                <Input placeholder="Produktnavn" disabled={isViewOnly()} />
              </Form.Item>

              <Form.Item
                name={["descriptions", "da", "description"]}
                rules={[{ required: true }]}
                label="Description"
              >
                <Input.TextArea
                  placeholder="Produkt Beskrivelse"
                  disabled={isViewOnly()}
                />
              </Form.Item>
            </Tabs.TabPane>
          </Tabs>

          <Form.Item
            name="sponsor"
            rules={[{ required: true }]}
            label="Sponsor"
          >
            <Input placeholder="Sponsor Name" disabled={isViewOnly()} />
          </Form.Item>

          <Form.Item
            name="sponsorLink"
            label="Sponsor Link"
            rules={[
              {
                type: "url",
                warningOnly: false,
                message: "Please enter a valid URL",
              },
            ]}
          >
            <Input placeholder="https://example.com" disabled={isViewOnly()} />
          </Form.Item>

          <Form.Item
            name="startingBid"
            rules={[{ required: true }]}
            label="Starting Bid"
          >
            <Input
              type="number"
              min={0}
              placeholder="Starting Bid Amount"
              disabled={isViewOnly()}
            />
          </Form.Item>

          <Form.Item
            name="biddingPeriod"
            rules={[{ required: true }]}
            label="Bidding Period"
          >
            <DatePicker.RangePicker
              format="YYYY-MM-DD HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={(dates) => {
                if (dates) {
                  form.setFieldsValue({
                    biddingStartDate: dates[0]?.toISOString(),
                    biddingEndDate: dates[1]?.toISOString(),
                  });
                }
              }}
              disabled={isViewOnly()}
            />
          </Form.Item>

          <Form.Item
            name="accessLevel"
            rules={[{ required: true }]}
            label="Access Level"
          >
            <Select placeholder="Select access level" disabled={isViewOnly()}>
              <Select.Option value="Basic">Regular</Select.Option>
              <Select.Option value="Premium">Premium</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Product Images"
            required
            help="Minimum 1 image, maximum 3 images. Each image must be less than 1MB"
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
                marginBottom: "16px",
              }}
            >
              {imageList.map((url, index) => (
                <div key={index} style={{ position: "relative" }}>
                  <Image
                    src={url}
                    alt={`Product ${index + 1}`}
                    style={{ width: 100, height: 100, objectFit: "cover" }}
                  />
                  {!isViewOnly() && (
                    <Button
                      size="small"
                      danger
                      style={{ position: "absolute", top: 0, right: 0 }}
                      onClick={() => handleImageRemove(index)}
                    >
                      X
                    </Button>
                  )}
                </div>
              ))}
              {!isViewOnly() && imageList.length < 3 && (
                <Upload
                  accept="image/*"
                  showUploadList={false}
                  beforeUpload={async (file) => {
                    await handleImageUpload(file);
                    return false;
                  }}
                >
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      border: "1px dashed #d9d9d9",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </Upload>
              )}
            </div>
          </Form.Item>

          {!isViewOnly() && (
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {editingProduct ? "Update" : "Create"}
              </Button>
            </Form.Item>
          )}
        </Form>
      </Modal>

      <Modal
        title="Auction Winner"
        visible={winnerModalVisible}
        onCancel={handleWinnerModalClose}
        footer={[
          <Button key="close" onClick={handleWinnerModalClose}>
            Close
          </Button>,
        ]}
      >
        {winner ? (
          <>
            <p>
              <strong>Winner:</strong> {winner.fullName}
            </p>
            <p>
              <strong>Email:</strong> {winner.email}
            </p>
            <p>
              <strong>Company:</strong> {winner.company || "N/A"}
            </p>
            <p>
              <strong>Department:</strong> {winner.department || "N/A"}
            </p>
            <p>
              <strong>Winning Bid:</strong> {winner.bidAmount.toLocaleString()}
            </p>
          </>
        ) : (
          <p style={{ textAlign: "center", fontSize: "16px", color: "#666" }}>
            The winner for this auction has not yet been awarded.
          </p>
        )}
      </Modal>

      <Notification
        open={notification.open}
        setOpen={(open) => setNotification({ ...notification, open })}
        title={notification.status === "SUCCESS" ? "Success" : "Error"}
        message={notification.message}
        severity={notification.status === "SUCCESS" ? "success" : "error"}
      />

      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default Auction;
