import './App.css';
import {BrowserRouter} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PublicRoutes from './routes/publicRoutes';
import AdminScreen from './views/admin/admin';
import { useEffect, createContext, useState } from 'react';
import { useAppSelector } from './app/hooks';

export const challengeContext = createContext({
  challengeData: {
    isSeriesChallenge: false,
    currentChallengeCount: 1,
    totalChallengeCount: 0,
    challengeItemDto: {},
    challengeItemList: [],
    hasMultipleReleaseDates: false,
    SeriesReleaseDate: '',
    isEditMode: false,
    isArchiveEditMode: false,
    challengeId: ''
  },
  setChallengeData: (data: any) => {}
});

function App() {
  const languages = ["en", "da", ];
  const userRole = localStorage.getItem('role');
  const { userData } = useAppSelector((state) => state.authentication);
  const [challengeData, setChallengeData] = useState<any>({
    isSeriesChallenge: false,
    currentChallengeCount: 1,
    totalChallengeCount: 0,
    challengeItemDto: {},
    challengeItemList: [],
    challengeByLangList: languages.map((lang: string) => {
      return {
          challengeTitle: '',
          challengeDescription: '',
          finishLineHeader: '',
          finishLineDescription: '',
          language: lang
      }
    }),
    hasMultipleReleaseDates: false,
    SeriesReleaseDate: '',
    isEditMode: false,
    isArchiveEditMode: false
  });
  let value:any = {challengeData, setChallengeData};

  useEffect(() => {
    console.log("Challenge Data Changed: ", challengeData);
  }, [challengeData]);
  
  useEffect(() => {
  }, [userData])

  return (
    <div className="App">
      <Helmet defaultTitle='GetUpp Dashboard' titleTemplate='%s - GetUpp Dashboard'>
        <meta name="description" content="Admin dashboard for GetUpp App" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <BrowserRouter>
        {userRole === "Admin" ?  <challengeContext.Provider value={value}><AdminScreen/></challengeContext.Provider> : <PublicRoutes/>}
      </BrowserRouter>
    </div>
  );
}

export default App;
