import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from '../../services/userService';
import { setMessage } from '../message/message';

const initialState: any = {
  usersList: [],
  companiesList: [],
  companies:[],
  company:{},
  videos: [],
  page: 1,
  isLoading: false,
  auctionList: [], // Added this state property
  challengesList: [] // Added this state property for clarity
}

export const getAllUsers = createAsyncThunk(
    'common/getAllUsers',
    async (params, thunkAPI) => {
        try {
            const response = await userService.getAllUsers(); 
            return response.data.details;
        } catch (error: any) {
            thunkAPI.dispatch(setMessage(error.response));
        }
    }
)

export const getAllCompanies = createAsyncThunk(
    'common/getAllCompanies',
    async (params, thunkAPI) => {
        try {
            const response = await userService.getAllCompanies();     
            return response.data.details;
        } catch (error: any) {
            thunkAPI.dispatch(setMessage(error.response));
        }
    }
)

export const getAllRegularChallenges = createAsyncThunk(
  'notifications/getAllRegularChallenges',
  async (params, thunkAPI: any) => {
      try {
          const response = await userService.getAllRegularChallenges();      
          return response.data.details;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
      }
  }
)

export const getPopularVideos = createAsyncThunk(
  'common/getPopularVideos',
  async (params: number, thunkAPI) => {
      try {
          const response = await userService.getPopularVideos(params);          
          return response.data.details;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
      }
  }
)

export const setPage = createAsyncThunk(
  'common/setPage',
  async (params: number, thunkAPI) => {
    return params;
  }
)

export const getCompanies = createAsyncThunk(
  'common/getCompanies',
  async (params, thunkAPI) => {
    try {
        const response = await userService.getCompanies();     
        return response.data.details;
    } catch (error: any) {
        thunkAPI.dispatch(setMessage(error.response));
    }
  }
)

export const getCompanyById = createAsyncThunk(
  'common/getCompanyById',
  async (params: string, thunkAPI) => {
    try {
        const response = await userService.getCompanyById(params);     
        return response.data.details;
    } catch (error: any) {
        thunkAPI.dispatch(setMessage(error.response));
    }
  }
)

export const saveCompany = createAsyncThunk(
  'common/saveCompany',
  async (params: any, thunkAPI) => {
    try {
        const response = await userService.saveCompany(params);     
        return response.data.details;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await thunkAPI.dispatch(setMessage(error.response));
        const response = await userService.saveCompany(params);
        return response.data.details;
      }
      else {
        thunkAPI.dispatch(setMessage(error.response));
      }
    }
  }
)

export const saveDepartment = createAsyncThunk(
  'common/saveDepartment',
  async (params: any, thunkAPI) => {
    try {
        const response = await userService.saveDepartment(params.companyId, params.data);     
        return response.data.details;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await thunkAPI.dispatch(setMessage(error.response));
        const response = await userService.saveDepartment(params.companyId, params.data);
        return response.data.details;
      }
      else {
        thunkAPI.dispatch(setMessage(error.response));
      }
    }
  }
)

export const updateDepartment = createAsyncThunk(
  'common/updateDepartment',
  async (params: any, thunkAPI) => {
    try {
        const response = await userService.updateDepartment(params.companyId, params.departmentId, params.data);     
        return response.data.details;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await thunkAPI.dispatch(setMessage(error.response));
        const response = await userService.updateDepartment(params.companyId, params.departmentId, params.data);
        return response.data.details;
      }
      else {
        thunkAPI.dispatch(setMessage(error.response));
      }
    }
  }
)

export const createCompany = createAsyncThunk(
  'common/createCompany',
  async (params: any, thunkAPI) => {
    try {
        const response = await userService.createCompany(params);     
        return response.data.details;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await thunkAPI.dispatch(setMessage(error.response));
        const response = await userService.createCompany(params);
        return response.data.details;
      }
      else {
        thunkAPI.dispatch(setMessage(error.response));
      }
    }
  }
)

export const deleteCompany = createAsyncThunk(
  'common/deleteCompany',
  async (params: string, thunkAPI) => {
    try {
        const response = await userService.deleteCompany(params);     
        return response.data.details;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await thunkAPI.dispatch(setMessage(error.response));
        const response = await userService.deleteCompany(params);
        return response.data.details;
      }
      else {
        thunkAPI.dispatch(setMessage(error.response));
      }
    }
  }
)

export const deleteDepartment = createAsyncThunk(
  'common/deleteDepartment',
  async (params: any, thunkAPI) => {
    try {
        const response = await userService.deleteDepartment(params.companyId, params.departmentId);     
        return response.data.details;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await thunkAPI.dispatch(setMessage(error.response));
        const response = await userService.deleteDepartment(params.companyId, params.departmentId);
        return response.data.details;
      }
      else {
        thunkAPI.dispatch(setMessage(error.response));
      }
    }
  }
)

export const updateCompanyLock = createAsyncThunk(
  'common/updateCompanyLock',
  async (params: any, thunkAPI) => {
    try {
        const response = await userService.updateCompanyLock(params.companyId, params.data);     
        return response.data.details;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await thunkAPI.dispatch(setMessage(error.response));
        const response = await userService.updateCompanyLock(params.companyId, params.data);
        return response.data.details;
      }
      else {
        thunkAPI.dispatch(setMessage(error.response));
      }
    }
  }
)

export const saveNewCompany = createAsyncThunk(
  'common/saveNewCompany',
  async (params: any, thunkAPI) => {
    try {
        const response = await userService.saveNewCompany(params);     
        return response.data.details;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await thunkAPI.dispatch(setMessage(error.response));
        const response = await userService.saveNewCompany(params);
        return response.data.details;
      }
      else {
        thunkAPI.dispatch(setMessage(error.response));
      }
    }
  }
)

export const getAllAuctionList = createAsyncThunk(
  'common/getAllAuctionList',
  async (params, thunkAPI) => {
    try {
      const response = await userService.getAllAuctionList();     
      return response.data.details;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await thunkAPI.dispatch(setMessage(error.response));
        const response = await userService.getAllAuctionList();
        return response.data.details;
      }
      else {
        thunkAPI.dispatch(setMessage(error.response));
      }
    }
  }
)

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.usersList = action.payload;
      state.isLoading = false;
    })
    builder.addCase(getAllUsers.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
      state.isLoading = false;
    })
    builder.addCase(getAllUsers.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(getAllCompanies.fulfilled, (state, action) => {
      state.companiesList = action.payload;
    })
    builder.addCase(getAllCompanies.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
    })
    builder.addCase(getPopularVideos.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(getPopularVideos.fulfilled, (state, action) => {
      state.videos = state.page === 1 ? [...action.payload] : [...state.videos, ...action.payload];

      if(action.payload.length > 0)
      {
        state.page += 1; 
      }
      state.isLoading = false;
    })
    builder.addCase(getPopularVideos.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
      state.isLoading = false;
    })
    builder.addCase(setPage.fulfilled, (state, action) => {
      state.page = action.payload;
    })
    builder.addCase(setPage.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
    })
    builder.addCase(getAllRegularChallenges.fulfilled, (state, action) => {
      state.challengesList = action.payload?.map((challenge: any) => {
        return {
          id: challenge.challengeId,
          name: challenge.challengeName
        }
      });
    })
    builder.addCase(getAllRegularChallenges.rejected, (state, action) => {
      console.log("Error fetching templates: ", action.payload);
    })
    
    builder.addCase(getCompanies.fulfilled, (state, action) => {
      state.companies = action.payload;
      state.isLoading = false;
    })
    builder.addCase(getCompanies.rejected, (state, action) => {
      console.log("Error fetching companies: ", action.payload);
      state.isLoading = false;
    })
    builder.addCase(getCompanies.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(getCompanyById.pending, (state, action) => {
      state.isLoading = true;
    })

    builder.addCase(getCompanyById.fulfilled, (state, action) => {
      state.company = action.payload;
      state.isLoading = false;
    })
    builder.addCase(getCompanyById.rejected, (state, action) => {
      console.log("Error fetching company: ", action.payload);
      state.isLoading = false;
    })
    builder.addCase(saveCompany.pending, (state, action) => {
      state.isLoading = true;
    })

    builder.addCase(saveCompany.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(saveCompany.rejected, (state, action) => {
      console.log("Error saving company: ", action.payload);
      state.isLoading = false;
    })
    builder.addCase(saveDepartment.pending, (state, action) => {
      state.isLoading = true;
    })

    builder.addCase(saveDepartment.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(saveDepartment.rejected, (state, action) => {
      console.log("Error saving department: ", action.payload);
      state.isLoading = false;
    })
    builder.addCase(updateDepartment.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(updateDepartment.fulfilled, (state, action) => {
      state.isLoading = false;
    })

    builder.addCase(createCompany.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(createCompany.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(createCompany.rejected, (state, action) => {
      console.log("Error creating company: ", action.payload);
      state.isLoading = false;
    })

    builder.addCase(updateCompanyLock.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(updateCompanyLock.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(updateCompanyLock.rejected, (state, action) => {
      console.log("Error updating company lock: ", action.payload);
      state.isLoading = false;
    })
    
    builder.addCase(saveNewCompany.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(saveNewCompany.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(saveNewCompany.rejected, (state, action) => {
      console.log("Error saving new company: ", action.payload);
      state.isLoading = false;
    })
    
    builder.addCase(getAllAuctionList.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(getAllAuctionList.fulfilled, (state, action) => {
      state.auctionList = action.payload;
      state.isLoading = false;
    })
    builder.addCase(getAllAuctionList.rejected, (state, action) => {
      console.log("Error fetching auction list: ", action.payload);
      state.isLoading = false;
    })
  },
})

export default commonSlice.reducer;