import { configureStore } from '@reduxjs/toolkit';
import authenticationReducer from '../features/authentication/authentication';
import messageReducer from '../features/message/message';
import QRCodesReducer from '../features/QRCodes/QRCodes';
import dashboardReducer from '../features/dashboard/dashboard';
import commonReducer from '../features/common/common';
import notificationsReducer from '../features/notificationConsole/notificationConsole';
import notificationTemplateReducer from '../features/notificationTemplate/notificationTemplate';
import challengesReducer from '../features/challenges/challenges';
import notificationDictionaryReducer from '../features/notificationDictionary/notificationDictionary';
import auctionReducer from '../features/auction/auction';


const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    message: messageReducer,
    QRCodes: QRCodesReducer,
    dashboard: dashboardReducer,
    common: commonReducer,
    notifications: notificationsReducer,
    notificationTemplate: notificationTemplateReducer,
    challenges: challengesReducer,
    notificationDictionary: notificationDictionaryReducer,
    auction: auctionReducer,
  },
  devTools: true,
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
