import { Col, Row, Typography, DatePicker, TimePicker, Space } from "antd";
import "./notificationConsole.css";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ButtonComponent from "../../components/button/button";
import RadioGroup from "../../components/radioGroup/radioGroup";
import InputField from "../../components/inputField/inputField";
import SelectComponent from "../../components/select/select";
import UploadCard from "../../components/cards/uploadCard";
import { useNavigate } from "react-router-dom";
import {
  getAllUsers,
  getAllCompanies,
  getAllRegularChallenges,
  getAllAuctionList,
} from "../../features/common/common";
import type { UploadFile } from "antd/es/upload/interface";
import type { RcFile } from "antd/es/upload";
import * as yup from "yup";
import { sendNotification } from "../../features/notificationConsole/notificationConsole";
import Notification from "../../components/notification/notification";
import CheckboxComponent from "../../components/checkbox/checkbox";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import NotificationOverview from "../createNotificationTemplate/notificationOverview/notificationOverview";

const { Title } = Typography;

function NotificationConsole() {
  const [valuesArray, setValues] = useState<string[]>([]);
  const [deliveryOptions, setDeliveryOptions] = useState<string[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { usersList, companiesList, challengesList, auctionList } =
    useAppSelector((state) => state.common);
  const { isLoading } = useAppSelector((state) => state.notifications);
  const [usersArray, setUsersArray] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [selectedSAS, setSelectedSAS] = useState<string[]>([]);
  const [selectAllSAS, setSelectAllSAS] = useState(false);
  const [DepartmentList, setDepartmentsList] = useState<any[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const { token } = useAppSelector((state) => state.message);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [qrCode, setQRCode] = useState("");
  const [isQRUploaded, setQRUploaded] = useState(false);
  const [formData, setFormData] = useState({
    message: "",
    options: 0,
    deliveryType: -1,
    scheduledDate: "",
    scheduledTime: "",
    challengeId: "",
    auctionId: "",
    screenType: "",
    url: null,
    isAppScreen: false,
    language: "",
    membership: "",
  });
  const [formErrors, setFormErrors] = useState({
    message: "",
    options: "",
    deliveryType: "",
    challengeId: "",
    url: "",
    isAppScreen: "",
  });
  const [uploadResponse, setUploadResponse] = useState({
    status: "",
    successMessage: "",
    errors: [],
  });
  const [open, setOpen] = useState(false);
  const [selectedCompaniesDepartments, setSelectedCompaniesDepartments] =
    useState(new Map());
  const [navigateTo, setNavigateTo] = useState<string>("");
  const languageOptions = ["en", "da"];
  const [isDepartmentSelectedAll, setIsDepartmentSelectedAll] = useState(false);
  const [isCompanySelectedAll, setIsCompanySelectedAll] = useState(false);
  const membershipOptions = ["Basic", "Premium"];
  const screenTypes = [
    { id: "Challenge", name: "Challenge" },
    { id: "Auction", name: "Auction" },
  ];

  useEffect(() => {
    setValues([
      "Send to all users",
      "Send to specific users",
      "Send to specific Companies",
      "Send to all SAs / specific SAs",
      "Send to people in OfficeFit only",
    ]);
    setDeliveryOptions(["Send Now", "Scheduled Date & Time"]);
    dispatch(getAllUsers());
    dispatch(getAllCompanies());
    dispatch(getAllRegularChallenges());
    dispatch(getAllAuctionList());
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    setUsersArray(
      usersList?.map((user: any) => {
        return {
          id: user.id,
          name: user.firstName + " " + user.lastName,
        };
      })
    );
    // eslint-disable-next-line
  }, [usersList]);

  useEffect(() => {
    let newArray: any = [];
    companiesList?.forEach((company: any) => {
      if (
        selectedCompanies.includes(company.id) &&
        company.departments.length
      ) {
        newArray.push({
          companyID: company.id,
          name: company.name,
          departments: company.departments,
        });
      }
    });
    setDepartmentsList(newArray);

    let revisedDepartmentsList: any = [];

    selectedDepartments.forEach((departmentID: any) => {
      let flag = false;

      selectedCompanies.forEach((selectedCompanyID: any) => {
        companiesList.forEach((companyObj: any) => {
          if (
            companyObj.id === selectedCompanyID &&
            companyObj.departments.length
          ) {
            companyObj.departments.forEach((departmentData: any) => {
              if (departmentData.id === departmentID) {
                flag = true;
              }
            });
          }
        });
      });

      if (flag) {
        revisedDepartmentsList.push(departmentID);
        flag = false;
      }
    });

    setSelectedDepartments(revisedDepartmentsList);
    // eslint-disable-next-line
  }, [selectedCompanies]);

  useEffect(() => {
    if (formData.deliveryType === 1)
      validateField(deliverySchema, "deliveryType", formData.deliveryType);
  }, [formData.scheduledDate, formData.scheduledTime]);

  const handleCreateNotification = () => {
    navigate("/notification-template");
  };

  const handleSelectCompany = (e: any) => {
    debugger;
    let difference = e.filter((data: any) => !selectedCompanies.includes(data));
    let reverseDifference = selectedCompanies.filter(
      (data: any) => !e.includes(data)
    );

    let totalCompanies = companiesList.length;

    if (difference.length) {
      setSelectedCompaniesDepartments((prev) => {
        let newVal = new Map(prev);
        newVal.set(difference[0], []);
        return newVal;
      });
    } else if (reverseDifference.length) {
      setSelectedCompaniesDepartments((prev) => {
        let newVal = new Map(prev);
        newVal.delete(reverseDifference[0]);
        return newVal;
      });
    }
    setSelectedCompanies(e);

    let totalDepartments = 0;
    let totalSelected = e.length;

    DepartmentList.forEach((companyData: any) => {
      totalDepartments += companyData.departments.length;
    });

    if (totalSelected === totalCompanies && totalSelected > 0) {
      setIsCompanySelectedAll(true);
    } else {
      setIsCompanySelectedAll(false);
    }

    setIsDepartmentSelectedAll(false);
  };

  const handleSelectAllCompanies = (e: any) => {
    setIsCompanySelectedAll(e.target.checked);

    if (e.target.checked === true) {
      let allCompaniesList: string[] = companiesList.map(
        (companyObj: any) => companyObj.id
      );
      var newCompanyMap = new Map();
      allCompaniesList.forEach((companyID: string) => {
        newCompanyMap.set(companyID, []);
      });
      setSelectedCompaniesDepartments(newCompanyMap);
      setSelectedCompanies(allCompaniesList);
    } else {
      setSelectedCompaniesDepartments(new Map());
      handleSelectCompany([]);
      setSelectedCompanies([]);
    }
  };

  const handleSelectAllDepartments = (e: any) => {
    debugger;
    setIsDepartmentSelectedAll(e.target.checked);

    if (e.target.checked === true) {
      let allDepartmentsList: string[] = [];

      selectedCompanies.forEach((selectedCompanyID: any) => {
        companiesList.forEach((companyObj: any) => {
          if (
            companyObj.id === selectedCompanyID &&
            companyObj.departments.length
          ) {
            companyObj.departments.forEach((departmentData: any) => {
              allDepartmentsList.push(departmentData.id);
            });
          }
        });
      });

      handleSelectDepartments(allDepartmentsList);
    } else {
      handleSelectDepartments([]);
    }
  };

  const handleSelectDepartments = (e: any) => {
    let difference = e.filter(
      (data: any) => !selectedDepartments.includes(data)
    );
    let reverseDifference = selectedDepartments.filter(
      (data: any) => !e.includes(data)
    );

    let totalSelected = e.length;
    let totalDepartments = 0;

    DepartmentList.forEach((companyData: any) => {
      totalDepartments += companyData.departments.length;
    });

    selectedCompanies.forEach((selectedCompanyID: any) => {
      companiesList.forEach((companyObj: any) => {
        if (
          companyObj.id === selectedCompanyID &&
          companyObj.departments.length
        ) {
          companyObj.departments.forEach((departmentData: any) => {
            if (difference.length && difference.includes(departmentData.id)) {
              let newDepartmentsList = selectedCompaniesDepartments.get(
                companyObj.id
              );

              difference.forEach((departmentID: string) => {
                if (
                  !companyObj.departments.some(
                    (d: any) => d.id === departmentID
                  )
                )
                  return;
                newDepartmentsList.push(departmentID);
              });

              selectedCompaniesDepartments.set(
                companyObj.id,
                Array.from(new Set(newDepartmentsList))
              );
            } else if (
              reverseDifference.length &&
              reverseDifference.includes(departmentData.id)
            ) {
              let oldDepartmentsList = selectedCompaniesDepartments.get(
                companyObj.id
              );
              let revisedDepartmentsList = oldDepartmentsList.filter(
                (departmentID: string) =>
                  !reverseDifference.includes(departmentID)
              );

              selectedCompaniesDepartments.set(
                companyObj.id,
                Array.from(new Set(revisedDepartmentsList))
              );
            }
          });
        }
      });
    });

    if (totalSelected === totalDepartments) {
      setIsDepartmentSelectedAll(true);
    } else {
      setIsDepartmentSelectedAll(false);
    }

    setSelectedDepartments(e);
  };
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleUploadChange = async (filesData: any) => {
    setFileList(filesData.fileList);

    let base64URL = await getBase64(
      filesData.fileList[0].originFileObj as RcFile
    );
    setQRCode(base64URL);
    setQRUploaded(true);
  };

  const onFileRemove = () => {
    setFileList([]);
    setQRUploaded(false);
    setQRCode("");
  };

  let schema = yup.object().shape({
    message: yup
      .string()
      .required("Please enter a message.")
      .max(200, "Message cannot be of more than 200 characters."),
    options: yup
      .number()
      .test(
        "Atleast One",
        "Please select atleast one value from the selected option",
        function (value) {
          let isValid = false;

          if (value === 0 || value === 4) return true;
          else {
            switch (value) {
              case 1:
                if (selectedUsers.length) isValid = true;
                break;
              case 2:
                if (selectedCompanies.length && selectedDepartments.length)
                  isValid = true;
                break;
              case 3:
                if (selectedSAS.length) isValid = true;
                break;
            }
            return isValid;
          }
        }
      ),
    deliveryType: yup
      .number()
      .test(
        "Delivery Type",
        "Please select a valid schedule.",
        function (value) {
          if (value === 0) return true;
          else if (
            value === 1 &&
            formData.scheduledDate &&
            formData.scheduledTime &&
            Object.keys(formData.scheduledDate).length !== 0 &&
            Object.keys(formData.scheduledTime).length !== 0
          )
            return true;
          else return false;
        }
      ),
  });

  let messageSchema = yup.object().shape({
    message: yup
      .string()
      .required("Please enter a message.")
      .max(200, "Message cannot be of more than 200 characters."),
  });

  let optionsSchema = yup.object().shape({
    options: yup
      .number()
      .test(
        "Atleast One",
        "Please select atleast one value from the selected option",
        function (value) {
          let isValid = false;

          if (value === 0 || value === 4) return true;
          else {
            switch (value) {
              case 1:
                if (selectedUsers.length) isValid = true;
                break;
              case 2:
                if (selectedCompanies.length && selectedDepartments.length)
                  isValid = true;
                break;
              case 3:
                if (selectedSAS.length) isValid = true;
                break;
            }
            return isValid;
          }
        }
      ),
  });

  let deliverySchema = yup.object().shape({
    deliveryType: yup
      .number()
      .test(
        "Delivery Type",
        "Please select a valid schedule.",
        function (value) {
          if (value === 0) return true;
          else if (
            value === 1 &&
            formData.scheduledDate &&
            formData.scheduledTime &&
            Object.keys(formData.scheduledDate).length !== 0 &&
            Object.keys(formData.scheduledTime).length !== 0
          )
            return true;
          else return false;
        }
      ),
  });

  const handleSelectAllUsers = (e: any) => {
    setSelectAllSAS(e.target.checked);

    if (e.target.checked === true) {
      let allUsersList: any = usersArray.map((user: any) => {
        return user.id;
      });
      setSelectedSAS(allUsersList);
    } else {
      setSelectedSAS([]);
    }
  };

  const validateField = (schemaName: any, name: any, value: any) => {
    schemaName
      .validate({ [name]: value }, { abortEarly: false })
      .then((response: any) => {
        setFormErrors({ ...formErrors, [name]: "" });
      })
      .catch((error: any) => {
        const errors = error.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]: error.errors[0],
          };
        }, {});
        setFormErrors({ ...formErrors, ...errors });
      });
  };

  const handleSendMessage = () => {
    schema
      .validate(formData, { abortEarly: false })
      .then((res) => {
        let newArray: any = [];

        setFormErrors({
          message: "",
          options: "",
          deliveryType: "",
          url: "",
          challengeId: "",
          isAppScreen: "",
        });
        debugger;
        Array.from(selectedCompaniesDepartments).map((companyData: any) => {
          let newObj = {
            [companyData[0]]: companyData[1],
          };
          newArray.push(newObj);
        });

        let notificationObj = {
          message: formData.message,
          ...(!formData.isAppScreen && { url: formData.url, screenName: null }),
          ...(formData.isAppScreen && {
            url: null,
            screenName: { Id:  formData.screenType == "Challenge" ? formData.challengeId : formData.auctionId, name: formData.screenType },
          }),
          ...(qrCode && { Base64ImageString: qrCode }),
          ...(formData.language && { Language: formData.language }),
          ...(formData.membership && { Membership: formData.membership }),
          ...(formData.options === 0 && { SendToAllUsers: true }),
          ...(formData.options === 1 && {
            SendToSpecificUsers: true,
            SpecificUsers: selectedUsers,
          }),
          ...(formData.options === 2 && {
            SendToSpecificCompanyPlusDepartment: true,
            SpecificCompanyPlusDepartment: newArray,
          }),
          ...(formData.options === 3 && selectAllSAS && { SendToAllSa: true }),
          ...(formData.options === 3 &&
            !selectAllSAS && {
              SendToSpecificSa: true,
              SpecificSa: selectedSAS,
            }),
          ...(formData.options === 4 && { SendToOfficefitUsers: true }),
          ...(formData.deliveryType === 1 && {
            IsScheduledNotification: true,
            ScheduleDateTime:
              formData.scheduledDate.toString() +
              formData.scheduledTime.toString(),
          }),
        };

        dispatch(sendNotification(notificationObj)).then((response) => {
          setUploadResponse(response.payload);
          setOpen(true);
        });
      })
      .catch((error) => {
        const errors = error.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]: error.errors[0],
          };
        }, {});
        setFormErrors(errors);
      });
  };

  const handleInputChange = (name: string, value: any) => {
    setFormData({ ...formData, [name]: value });

    switch (name) {
      case "message":
        validateField(messageSchema, name, value);
        break;
      case "options":
        validateField(optionsSchema, name, value);
        setSelectedUsers([]);
        break;
      case "deliveryType":
        setFormErrors({ ...formErrors, [name]: "" });
        validateField(deliverySchema, name, value);
        break;
      case "scheduledDate":
        setFormData({ ...formData, [name]: value.format("YYYY-MM-DD") });
        break;
      case "scheduledTime":
        setFormData({ ...formData, [name]: value.format("THH:mm:ssZ") });
        break;
      case "language":
        var lang = value === 0 ? "en" : value === 1 ? "da" : "";
        setFormData({ ...formData, [name]: lang });
        break;
      case "membership":
        var memberhip = value === 0 ? "Basic" : value === 1 ? "Premium" : "";
        setFormData({ ...formData, [name]: memberhip });
        break;
    }
  };

  const handleScreenTypeChange = (value: string) => {
    setFormData({
      ...formData,
      screenType: value,
      challengeId: "",
      auctionId: "",
    });
  };

  const getAppScreenName = () => {
    if (!formData.isAppScreen) return "";

    if (formData.screenType === "Challenge" && formData.challengeId) {
      return `challenge?id={${formData.challengeId}}`;
    }

    if (formData.screenType === "Auction" && formData.auctionId) {
      return `auction?id={${formData.auctionId}}`;
    }

    return "";
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <Row className="notification-container">
        <Row className="notification-header">
          <Title level={2} className="title">
            Notification Console
          </Title>
          <Col>
            <Space>
              <ButtonComponent
                title="Create Notification Template"
                className="create-btn"
                onClick={handleCreateNotification}
              />
              <ButtonComponent
                title="View all"
                type="link"
                onClick={() => handleNavigate("/notification-templates")}
              />
            </Space>
          </Col>
        </Row>

        <Row className="form" gutter={[40, 20]}>
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={10}
            style={{ marginTop: "1em" }}
          >
            <Title level={5} className="input-label">
              Message
            </Title>
            <InputField
              placeholder="Enter Message"
              name="message"
              type="text"
              onChange={(e) => handleInputChange("message", e.target.value)}
              autoComplete="false"
              className="message-input"
            />
            <Title level={5} className="input-info">
              Max. upto 200 Characters
            </Title>
            {formErrors.message && (
              <Title level={5} className="error-label" type="danger">
                {formErrors.message}
              </Title>
            )}

            {!formData.isAppScreen && (
              <>
                <Title level={5} className="input-label">
                  Web Link
                </Title>
                <InputField
                  placeholder="Enter Web Link"
                  name="web-link"
                  type="text"
                  onChange={(e) => handleInputChange("url", e.target.value)}
                  autoComplete="false"
                  className="message-input"
                />
                {formErrors.url && (
                  <Title level={5} className="error-label" type="danger">
                    {formErrors.url}
                  </Title>
                )}
              </>
            )}

            {formData.isAppScreen && (
              <Row gutter={[20, 0]} style={{ marginTop: "1.2em" }}>
                <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                  <Title level={5} className="input-label">
                    Screen Type
                  </Title>
                  <SelectComponent
                    className="screen-type"
                    list={screenTypes}
                    value={formData.screenType}
                    placeholder="Select Screen Type"
                    onChange={(e) => handleScreenTypeChange(e)}
                    selectAll={false}
                    isOptionGroup={false}
                    hasSingleSelection={true}
                  />
                </Col>

                {formData.screenType && (
                  <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                    <Title level={5} className="input-label">
                      {formData.screenType}
                    </Title>
                    <SelectComponent
                      className={formData.screenType.toLowerCase()}
                      list={
                        formData.screenType === "Challenge"
                          ? challengesList
                          : auctionList
                      }
                      value={
                        formData.screenType === "Challenge"
                          ? formData.challengeId
                          : formData.auctionId
                      }
                      placeholder={`Select ${formData.screenType}`}
                      onChange={(e) =>
                        handleInputChange(
                          formData.screenType === "Challenge"
                            ? "challengeId"
                            : "auctionId",
                          e
                        )
                      }
                      selectAll={false}
                      isOptionGroup={false}
                      hasSingleSelection={true}
                    />
                  </Col>
                )}

                {((formData.screenType === "Challenge" &&
                  formData.challengeId) ||
                  (formData.screenType === "Auction" &&
                    formData.auctionId)) && (
                  <Col
                    style={{ marginTop: `1em` }}
                    xs={24}
                    sm={24}
                    md={6}
                    lg={8}
                    xl={12}
                  >
                    <Title level={5} className="input-label">
                      App Screen Name
                    </Title>
                    <InputField
                      placeholder="Enter App Screen Name"
                      name="app-screen-name"
                      type="text"
                      value={getAppScreenName()}
                      autoComplete="false"
                      className="message-input"
                      disabled={true}
                    />
                  </Col>
                )}
              </Row>
            )}

            <CheckboxComponent
              title="Is In-App Screen?"
              className="is-app-screen"
              onChange={(e) =>
                handleInputChange("isAppScreen", e.target.checked)
              }
              isCheckboxGroup={false}
            />

            <Row style={{ marginTop: "1em" }}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <RadioGroup
                  className="radio-group-container"
                  direction="vertical"
                  selectedValue={formData.options}
                  values={valuesArray}
                  name="options"
                  onChange={(e: any) =>
                    handleInputChange("options", e.target.value)
                  }
                  hasChild={true}
                  childIndex={2}
                  childComponent={
                    <>
                      {selectedCompanies.length > 0 && (
                        <span
                          style={{
                            display: "inline-block",
                            margin: "2.5em 1em 0em 0em",
                          }}
                        >
                          Send to specific Departments
                        </span>
                      )}
                    </>
                  }
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                className="dropdown-container"
              >
                {formData.options === 1 && (
                  <SelectComponent
                    className="specific-users"
                    list={usersArray}
                    selectedList={selectedUsers}
                    placeholder="Select Users"
                    onChange={(e: any) => setSelectedUsers(e)}
                    mode="multiple"
                    selectAll={false}
                  />
                )}

                {formData.options === 2 && (
                  <>
                    <SelectComponent
                      className="specific-companies"
                      list={companiesList}
                      selectedList={selectedCompanies}
                      placeholder="Select Companies"
                      onChange={(e: any) => handleSelectCompany(e)}
                      mode="multiple"
                      selectAll={true}
                      allChecked={isCompanySelectedAll}
                      onSelectAll={(e: any) => handleSelectAllCompanies(e)}
                    />
                    {selectedCompanies.length > 0 && (
                      <SelectComponent
                        className="specific-departments"
                        list={DepartmentList}
                        selectedList={selectedDepartments}
                        placeholder="Select Departments"
                        mode="multiple"
                        selectAll={true}
                        isOptionGroup={true}
                        onSelectAll={(e: any) => handleSelectAllDepartments(e)}
                        allChecked={isDepartmentSelectedAll}
                        onChange={(e: any) => handleSelectDepartments(e)}
                      />
                    )}
                  </>
                )}
                {formData.options === 3 && (
                  <SelectComponent
                    className="specific-sas"
                    list={usersArray}
                    selectedList={selectedSAS}
                    placeholder="Select Users"
                    mode="multiple"
                    onChange={(e: any) => {
                      setSelectedSAS(e);
                      setSelectAllSAS(false);
                    }}
                    onSelectAll={handleSelectAllUsers}
                    allChecked={selectAllSAS}
                    selectAll={true}
                  />
                )}
              </Col>
              {formErrors.options && (
                <Title level={5} className="error-label" type="danger">
                  {formErrors.options}
                </Title>
              )}
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Title level={5} className="input-label">
                  User language
                </Title>
                <SelectComponent
                  allowClear={true}
                  className="delivery-date"
                  list={languageOptions}
                  placeholder="Select"
                  onChange={(e) => handleInputChange("language", e)}
                  selectAll={false}
                  isOptionGroup={false}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Title level={5} className="input-label">
                  Membership
                </Title>
                <SelectComponent
                  allowClear={true}
                  className="delivery-date"
                  list={membershipOptions}
                  placeholder="Select"
                  onChange={(e) => handleInputChange("membership", e)}
                  selectAll={false}
                  isOptionGroup={false}
                />
              </Col>
            </Row>
            <Row style={{ margin: "1em 0em 0em 0em" }}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Title level={5} className="input-label">
                  Delivery Date
                </Title>
                <SelectComponent
                  className="delivery-date"
                  list={deliveryOptions}
                  placeholder="Select"
                  onChange={(e) => handleInputChange("deliveryType", e)}
                  selectAll={false}
                  isOptionGroup={false}
                />
              </Col>
              {/* <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                        <Title level={5} className="template-name-label">Template Name</Title>
                        <SelectComponent className="template-name" list={deliveryOptions} placeholder="Select" selectAll={false} isOptionGroup={false}/>
                    </Col> */}
            </Row>

            {formData.deliveryType === 1 && (
              <Row style={{ margin: "1.5em 0em 0em 0em", rowGap: "1.5em" }}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Title level={5} className="input-label">
                    Scheduled Date
                  </Title>
                  <DatePicker
                    onChange={(e) => handleInputChange("scheduledDate", e)}
                    className="date-picker"
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Title level={5} className="input-label">
                    Scheduled Time
                  </Title>
                  <TimePicker
                    onChange={(e) => handleInputChange("scheduledTime", e)}
                    className="time-picker"
                  />
                </Col>
              </Row>
            )}
            {formErrors.deliveryType && (
              <Title level={5} className="error-label" type="danger">
                {formErrors.deliveryType}
              </Title>
            )}
            <ButtonComponent
              title="Send Message"
              onClick={handleSendMessage}
              className="send-message"
            />
          </Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={14}>
            <NotificationOverview
              hasMultipleNotifications={false}
              data={formData}
            />
          </Col>
        </Row>

        {/* <Col xs={24} sm={24} md={6} lg={8} xl={10} className="upload-card-container"></Col>
                <UploadCard className="notification-upload-card" onChange={handleUploadChange} onClose={onFileRemove} fileList={fileList} isQRUploaded={isQRUploaded} previewImage={qrCode}/>
            </Col> */}
      </Row>
      <Notification
        open={open}
        setOpen={setOpen}
        title={uploadResponse?.status === "SUCCESS" ? "Success" : "Error"}
        message={
          uploadResponse?.successMessage
            ? uploadResponse.successMessage
            : uploadResponse?.errors
            ? uploadResponse.errors[0]
            : "Something went wrong, Please try again."
        }
        severity={uploadResponse?.status === "SUCCESS" ? "success" : "error"}
      />
    </div>
  );
}

export default NotificationConsole;
