import { Col, Row, Typography, TimePicker, Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import ButtonComponent from '../../../components/button/button';
import InputField from '../../../components/inputField/inputField';
import SelectComponent from '../../../components/select/select';
import UploadCard from '../../../components/cards/uploadCard';
import { useNavigate } from "react-router-dom";
import type { UploadFile } from 'antd/es/upload/interface';
import type { RcFile } from 'antd/es/upload';
import './notificationTemplate.css';
import CheckboxComponent from '../../../components/checkbox/checkbox';
import * as yup from 'yup';
import moment from 'moment';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { getAllRegularChallenges } from '../../../features/common/common';
import NotificationOverview from '../notificationOverview/notificationOverview';

const { Title } = Typography;

const screenTypes = [
  { id: "Challenge", name: "Challenge" },
  { id: "Auction", name: "Auction" },
];

function NotificationTemplate({flowData, setFlowData, setNotificationTemplateVisibilty, saveTemplate,setTemplateIndex}: any) {
  const [deliveryOptions, setDeliveryOptions] = useState<string[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState('');
  const [isQRConnected, setQRConnected] = useState(false);
  const [formData, setFormData] = useState({
    templateName: '',
    message: '',
    deliveryType: -1,
    daysCount: 0,
    scheduleTime: '',
    isAppScreen: false,
    url: null,
    challengeId: '',
    auctionId: '',
    language: '',
    sequenceNumber: 0,
    membership: '',
    screenType: ''
  });
  const [formErrors, setFormErrors] = useState({
    templateName: '',
    message: '',
    deliveryType: '',
    daysCount: '',
    scheduleTime: ''
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.message);
  const { challengesList, auctionList } = useAppSelector((state) => state.common);
  const [deliveryOptionSelected, setDeliveryOptionSelected] = useState<null | number>(null);
  const [notificationFlowIndex, setNotificationFlowIndex] = useState<null | number>(null);
  const [languageSelected, setLanguageSelected] = useState<null | string>(null);
  const languageOptions = ["en","da"];
  const membershipOptions = ["Basic","Premium" ];
  const [selectedChallenge, setSelectedChallenge] = useState<string>('');
  const [selectedAuction, setSelectedAuction] = useState<string>('');

  useEffect(() => {
    setDeliveryOptions(['Send Now', 'Scheduled Date & Time']);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getAllRegularChallenges());
    // eslint-disable-next-line
  }, [token]);

  let schema = yup.object().shape({
    templateName: yup.string().required("Please enter a template name.").max(200, "Template name cannot be of more than 200 characters."),
    message: yup.string().required("Please enter a message.").max(200, "Message cannot be of more than 200 characters."),
    deliveryType: yup.number().test('Delivery Type','Please select a valid schedule.', function(value){
      if(value === 0 || value === 1)
        return true;
      else
        return false;
    })
  });

  let QRSchema = yup.object().shape({
    templateName: yup.string().required("Please enter a template name.").max(200, "Template name cannot be of more than 200 characters."),
    message: yup.string().required("Please enter a message.").max(200, "Message cannot be of more than 200 characters."),
    daysCount: yup.number().required("Please enter valid days count.").min(1, "Days count must be greater than 0.").max(50, "Days count cannot be greater than 50"),
    scheduleTime: yup.string().required("Please enter a valid schedule time.")
  });

  let templateSchema = yup.object().shape({
    templateName: yup.string().required("Please enter a template name.").max(200, "Template name cannot be of more than 200 characters.")
  });

  let messageSchema = yup.object().shape({
    message: yup.string().required("Please enter a message.").max(200, "Message cannot be of more than 200 characters.")
  });

  let deliverySchema = yup.object().shape({
    deliveryType: yup.number().test('Delivery Type','Please select a valid schedule.', function(value){
      if(value === 0 || value === 1)
        return true;
      else
        return false;
    })
  });

  let daysSchema = yup.object().shape({
    daysCount: yup.number().required("Please enter valid days count.").min(1, "Days count must be greater than 0.").max(50, "Days count cannot be greater than 50")
  });

  let scheduleSchema = yup.object().shape({
    scheduleTime: yup.string().required("Please enter a valid schedule time.")
  });

  const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

  const handleUploadChange = async (filesData: any) => {
    setFileList(filesData.fileList);

    let base64URL = await getBase64(filesData.fileList[0].originFileObj as RcFile);
    setPreviewImage(base64URL);
  };
  
  const onFileRemove = () => {
    setFileList([]);
  };

  const handleGoBack = () => {
    navigate('/notification-console');
  };

  const handleConnectQRCode = (e: any) => {
    setQRConnected(e.target.checked);
  };

  const handleNewFlow = () => {
    if(isQRConnected)
    { 
      QRSchema.validate({...formData, templateName: flowData.TemplateName ? flowData.TemplateName : formData.templateName}, {abortEarly: false})
      .then(res => {
        
        var scheduleDateTime = moment(formData.scheduleTime).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

        if(notificationFlowIndex == null) {
          setFlowData({
            Id : flowData.Id,
            TemplateName: formData.templateName,
            ConnectToQrCode: isQRConnected,
            Templates: [
              ...flowData.Templates, 
              {
                Message: formData.message, 
                Days: formData.daysCount, 
                scheduleTime: scheduleDateTime,
                scheduleDateTime: scheduleDateTime,
                ...(!formData.isAppScreen && { isAppScreen: formData.isAppScreen, url: formData.url ? formData.url : null, screenName: null }),
                ...(formData.isAppScreen && { isAppScreen: formData.isAppScreen, url: null, screenName: { Id:  formData.screenType == "Challenge" ? formData.challengeId : formData.auctionId, name: formData.screenType } }),
                language : formData.language,
                membership: formData.membership,
                sequenceNumber: flowData.Templates.length + 1
              }
            ]
          });
        }
        else {
          const templates = [...flowData.Templates];
          templates[notificationFlowIndex - 1] = {
            ...flowData.Templates[notificationFlowIndex - 1],
            Message: formData.message, 
            Days: formData.daysCount, 
            scheduleTime: scheduleDateTime,
            scheduleDateTime: scheduleDateTime,
            ...(!formData.isAppScreen && { isAppScreen: formData.isAppScreen, url: formData.url ? formData.url : null, screenName: null }),
            ...(formData.isAppScreen && { isAppScreen: formData.isAppScreen, url: null, screenName: { Id:  formData.screenType == "Challenge" ? formData.challengeId : formData.auctionId, name: formData.screenType } }),
            language : formData.language,
            membership: formData.membership
          }

          setFlowData({
            Id : flowData.Id,
            TemplateName: formData.templateName,
            ConnectToQrCode: isQRConnected,
            Templates: [...templates]
          });
        }
        
        
        setFormData({...formData, message: '', daysCount: 0, scheduleTime: '', challengeId: '',language: '', sequenceNumber: 0});
        setFormErrors({
          templateName: '',
          message: '',
          deliveryType: '',
          daysCount: '',
          scheduleTime: ''
        });
      })
      .catch(error => {
        const errors = error.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]: error.errors[0],
          }
        }, {});
        setFormErrors(errors);
      });
    }
    else
    {
      schema.validate({...formData, templateName: flowData.TemplateName ? flowData.TemplateName : formData.templateName}, {abortEarly: false})
      .then(res => {
        if(notificationFlowIndex === null) {
          setFlowData({
            Id : flowData.Id,
            TemplateName: flowData.TemplateName ? flowData.TemplateName : formData.templateName,
            ConnectToQrCode: isQRConnected,
            Templates: [
              ...flowData.Templates,
              {
                Message: formData.message, 
                DeliveryType: formData.deliveryType,
                ...(!formData.isAppScreen && { isAppScreen: formData.isAppScreen, url: formData.url ? formData.url : null, screenName: null }),
                ...(formData.isAppScreen && { isAppScreen: formData.isAppScreen, url: null, screenName: { Id:  formData.screenType == "Challenge" ? formData.challengeId : formData.auctionId, name: formData.screenType }}),
                language : formData.language,
                membership: formData.membership,
                isScheduledNotification: formData.deliveryType === 1 ? true : false,
                sequenceNumber: flowData.Templates.length + 1
              }
            ]
          })
        }
        else {
          const templates = [...flowData.Templates];
          templates[notificationFlowIndex - 1] = {
            ...flowData.Templates[notificationFlowIndex - 1],
            Message: formData.message, 
            DeliveryType: formData.deliveryType,
            ...(!formData.isAppScreen && { isAppScreen: formData.isAppScreen, url: formData.url ? formData.url : null, screenName: null }),
            ...(formData.isAppScreen && { isAppScreen: formData.isAppScreen, url: null, screenName: { Id:  formData.screenType == "Challenge" ? formData.challengeId : formData.auctionId, name: formData.screenType } }),
            language : formData.language,
            membership: formData.membership,
            isScheduledNotification: formData.deliveryType === 1 ? true : false
          }

          setFlowData({
            Id : flowData.Id,
            TemplateName: flowData.TemplateName ? flowData.TemplateName : formData.templateName,
            ConnectToQrCode: isQRConnected,
            Templates: [...templates]
          });
        }
        setFormData({...formData, message: '', sequenceNumber: 0});
        setFormErrors({
          templateName: '',
          message: '',
          deliveryType: '',
          daysCount: '',
          scheduleTime: ''
        });
        setNotificationTemplateVisibilty(false);
      })
      .catch(error => {
        const errors = error.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]: error.errors[0],
          }
        }, {});
        setFormErrors(errors);
      });
    }
  };

  const handleSaveFlow = () => {
    saveTemplate();
    setFormData({...formData, templateName: '', sequenceNumber: 0});
  };

  const validateField = (schemaName: any, name: any, value: any) => {
    schemaName.validate({[name]: value}, {abortEarly: false})
    .then((response: any) => { setFormErrors({...formErrors, [name]: ""}); })
    .catch((error: any) => {
      const errors = error.inner.reduce((acc: any, error: any) => {
        return {
          ...acc,
          [error.path]: error.errors[0],
        }
      }, {})
      setFormErrors({...formErrors, ...errors});
    })
  };

  const handleInputChange = (name: string, value: any) => {
    if(name != 'scheduleTime') {
      setFormData({...formData, [name]: value});
    }
    else {
      setFormData({...formData, [name]: value.format('YYYY-MM-DDTHH:mm:ss[Z]')});
    }
    
    switch(name)
    {
      case 'templateName': 
        validateField(templateSchema, name, value);
        break;
      case 'message':
        validateField(messageSchema, name, value);
        break;
      case 'deliveryType':
        validateField(deliverySchema, name, value);
        setDeliveryOptionSelected(value);
        break;
      case 'daysCount':
        validateField(daysSchema, name, value);
        break;
      case 'scheduleTime':
        validateField(scheduleSchema, name, value);
        break;
      case 'language':
        setLanguageSelected(value);
        var lang = value === 0 ? "en" : "da";
        setFormData({...formData, [name]: lang});
        break;
      case 'membership':
        var membership = value != null ? value === 0 ? "Basic" : "Premium" : "";
        setFormData({...formData, [name]: membership});
        break;
      case 'screenType':
        setFormData({...formData, [name]: value, challengeId: '', auctionId: ''});
        setSelectedChallenge('');
        setSelectedAuction('');
        break;
      case 'challengeId':
        setSelectedChallenge(value);
        setFormData({...formData, challengeId: value, screenType: "Challenge"});
        break;
      case 'auctionId':
        setSelectedAuction(value);
        setFormData({...formData, auctionId: value, screenType: "Auction"});
        break;
    }
  };

  const getAppScreenName = () => {
    if (!formData.isAppScreen) return "";

    if (formData.screenType === "Challenge" && formData.challengeId) {
      return `challenge?id={${formData.challengeId}}`;
    }

    if (formData.screenType === "Auction" && formData.auctionId) {
      return `auction?id={${formData.auctionId}}`;
    }

    return "";
  };

  const handleFlowEdit = (index: number) => {
    setTemplateIndex(index);
    setNotificationFlowIndex(index);
    var template = flowData.Templates[index - 1];
    var isAppScreen = template.screenName ? true : false;
    //screeName is a string with value of challenge?id=81bf6bc3-8be4-44da-9b9b-e21b5667bf03
    //extract the challenge id from the above string
    var challengeId = template.screenName ? template.screenName.id : '';
    var deliveryType = template.isScheduledNotification ? 'Scheduled Date & Time': 'Send Now';
    var deliveryTypeValue = template.isScheduledNotification ? 1 : 0;
    var updatedFormData = {
      isAppScreen : isAppScreen,
      url : template.url,
      message: template.Message ? template.Message : template.message,
      deliveryType : deliveryTypeValue,
      language : template.language,
      daysCount: template.days,
      scheduleTime: template.scheduleDateTime,
      templateName: flowData.TemplateName ? flowData.TemplateName : formData.templateName,
      sequenceNumber: template.sequenceNumber,
      membership: template.membership ? template.membership : template.Membership,
      ...{screenType: template.screenName ? template.screenName.name : ''},
      ...{auctionId: template.screenName.name === "Auction" ? template.screenName.id : ''},
      ...{challengeId: template.screenName.name === "Challenge" ? template.screenName.id : ''}
    }
    debugger;
    setFormData({
      ...formData,
      ...updatedFormData
    });
    setDeliveryOptionSelected(deliveryTypeValue);
    setLanguageSelected(template.language);
    setQRConnected(flowData.ConnectToQrCode || flowData.connectToQrCode);
  }

  var nextStepButtonText = "Next";

  if(notificationFlowIndex === null && flowData?.Templates?.length > 0) {
    nextStepButtonText = "Add new flow";
  }

  return (
    <div>
        <Row className="notification-template-container">
            <Row className="header">
                <Title level={2} className="title">Create Notification Template</Title>
                <ButtonComponent title="Go Back" className="create-btn" onClick={handleGoBack}/>
            </Row>

            <Row className="form" gutter={[40, 20]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={10} style={{marginTop:'1em'}}>
                  <Title level={5} className="input-label">Template Name</Title>
                  <InputField placeholder="Enter Template Name" name="templateName" type="text" value={!isQRConnected && flowData.TemplateName ? flowData.TemplateName : formData.templateName} onChange={(e) => handleInputChange("templateName", e.target.value)} autoComplete="false" className="message-input"/>
                  <Title level={5} className="input-info">Max. upto 200 Characters</Title>
                  { formErrors.templateName && <Title level={5} className="error-label" type="danger">{formErrors.templateName}</Title>}

                  {
                    !formData.isAppScreen &&
                    <>
                      <Title level={5} className="input-label">Web Link</Title>
                      <InputField placeholder="Enter Web Link" name="web-link" type="text" value={formData.url} onChange={(e) => handleInputChange("url", e.target.value)} autoComplete="false" className="message-input"/>
                    </>
                  }
                  
                  {
                    formData.isAppScreen && 
                    <>
                      <Row gutter={[20, 0]} style={{marginTop:'1.2em'}}>			
                        <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                          <Title level={5} className="input-label">Screen Type</Title>
                          <SelectComponent 
                            className="screen-type" 
                            list={screenTypes} 
                            value={formData.screenType} 
                            placeholder="Select Screen Type" 
                            onChange={(e) => handleInputChange('screenType', e)} 
                            selectAll={false} 
                            isOptionGroup={false} 
                            hasSingleSelection={true} 
                          />
                        </Col>
                        
                        {formData.screenType && (
                          <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                            <Title level={5} className="input-label">
                              {formData.screenType}
                            </Title>
                            <SelectComponent
                              className={formData.screenType.toLowerCase()}
                              list={
                                formData.screenType === "Challenge"
                                  ? challengesList
                                  : auctionList
                              }
                              value={
                                formData.screenType === "Challenge"
                                  ? formData.challengeId
                                  : formData.auctionId
                              }
                              placeholder={`Select ${formData.screenType}`}
                              onChange={(e) =>
                                handleInputChange(
                                  formData.screenType === "Challenge"
                                    ? "challengeId"
                                    : "auctionId",
                                  e
                                )
                              }
                              selectAll={false}
                              isOptionGroup={false}
                              hasSingleSelection={true}
                            />
                          </Col>
                        )}

                        {((formData.screenType === "Challenge" &&
                          formData.challengeId) ||
                          (formData.screenType === "Auction" &&
                            formData.auctionId)) && (
                          <Col
                            style={{ marginTop: `1em` }}
                            xs={24}
                            sm={24}
                            md={6}
                            lg={8}
                            xl={12}
                          >
                            <Title level={5} className="input-label">
                              App Screen Name
                            </Title>
                            <InputField
                              placeholder="Enter App Screen Name"
                              name="app-screen-name"
                              type="text"
                              value={getAppScreenName()}
                              autoComplete="false"
                              className="message-input"
                              disabled={true}
                            />
                          </Col>
                        )}

                      </Row>
                      
                    </>
                  }

                  <CheckboxComponent title="Is In-App Screen?" defaultChecked={formData.isAppScreen} className="is-app-screen" onChange={(e) => handleInputChange("isAppScreen", e.target.checked)} isCheckboxGroup={false} />

                  <Title level={5} className="input-label">Notification Number {formData.sequenceNumber ? formData.sequenceNumber :  flowData.Templates.length + 1}</Title>
                  <InputField placeholder="Enter Message" name="message" type="text" value={formData.message} onChange={(e) => handleInputChange("message", e.target.value)} autoComplete="false" className="message-input"/>
                  <Title level={5} className="input-info">Max. upto 200 Characters</Title>
                  { formErrors.message && <Title level={5} className="error-label" type="danger">{formErrors.message}</Title>}

                  <Row style={{margin:'1em 0em 0em 0em'}}>			
                      <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                          <Title level={5} className="input-label">User language</Title>
                          <SelectComponent allowClear={true} className="delivery-date" list={languageOptions} value={languageSelected} placeholder="Select" selectAll={false} onChange={e => handleInputChange("language", e)} />
                      </Col>
                    </Row>
                  <Row>
                    <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                      <Title level={5} className="input-label">Membership</Title>
                      <SelectComponent allowClear={true} className="delivery-date" list={membershipOptions} value={formData.membership ? formData.membership == "Basic" ? 0 : 1 : null}  placeholder="Select" selectAll={false} onChange={e => handleInputChange("membership", e)} />
                    </Col>
                  </Row>

                  {
                    !isQRConnected && 
                    <Row style={{margin:'1em 0em 0em 0em'}}>			
                      <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                          <Title level={5} className="input-label">Delivery Date</Title>
                          <SelectComponent className="delivery-date" list={deliveryOptions} value={deliveryOptionSelected} placeholder="Select" selectAll={false} onChange={e => handleInputChange("deliveryType", e)} />
                      </Col>
                    </Row>
                  }
                  { formErrors.deliveryType && <Title level={5} className="error-label" type="danger">{formErrors.deliveryType}</Title>}

                  {
                    isQRConnected &&
                    <Row gutter={[20, 0]} style={{marginTop:'1.2em'}}>			
                      <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                          <Title level={5} className="input-label">Number of Days</Title>
                          <InputField placeholder="Add Days" name="addDays" type="number" value={formData.daysCount} onChange={(e) => handleInputChange("daysCount", e.target.value)} autoComplete="false" className="message-input full-width"/>
                          { formErrors.daysCount && <Title level={5} className="error-label" type="danger">{formErrors.daysCount}</Title>}
                      </Col>
                      
                      <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                        <Title level={5} className="input-label">Schedule Time (UTC)</Title>
                        <TimePicker className="message-input full-width" name="addTime" defaultValue={formData.scheduleTime && Object.keys(formData.scheduleTime).length !== 0  ? moment(formData.scheduleTime).utc() : undefined} onChange={(e) => handleInputChange("scheduleTime", e)}/>
                        { formErrors.scheduleTime && <Title level={5} className="error-label" type="danger">{formErrors.scheduleTime}</Title>}
                      </Col>
                    </Row> 
                  }
                  {
                    flowData?.Templates?.length > 0
                     ? <Checkbox checked={isQRConnected} disabled={true} className="connect-qr" >Connect To QR Code</Checkbox> :
                    <CheckboxComponent title="Connect To QR Code" defaultChecked={isQRConnected} className="connect-qr" disabled={flowData.Templates.length > 0 ? true : false} onChange={handleConnectQRCode} isCheckboxGroup={false} />
                  }
                
                  <ButtonComponent title={nextStepButtonText} className="new-flow-btn" onClick={handleNewFlow}/>

                  {/* {
                    flowData.Templates.length > 0 &&<ButtonComponent title="Save Flow" className="save-flow-btn" onClick={handleSaveFlow} />
                  } */}
                  
              </Col>
              <Col xs={24} sm={24} md={16} lg={16} xl={14}>
                  <NotificationOverview hasMultipleNotifications={true} data={flowData} handleFlowEdit={handleFlowEdit} handleSaveFlow={handleSaveFlow} />
              </Col>
            </Row>
            {/* <Col xs={24} sm={24} md={6} lg={8} xl={10} className="upload-card-container">
                <UploadCard className="notification-upload-card" onChange={handleUploadChange} onClose={onFileRemove} fileList={fileList} previewImage={previewImage}/>
            </Col> */}
        </Row>
    </div>
  )
};

export default NotificationTemplate;