import { Row, Col, Typography, Drawer } from "antd";
import "./header.css";
import ButtonComponent from "../button/button";
import { Link } from "react-router-dom";
import { AppDispatch } from "../../app/store";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { logout } from "../../features/authentication/authentication";
import common from "../../common/common";
import { MenuOutlined } from "@ant-design/icons";

const { Title } = Typography;

function Header() {
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState(false);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    common.on("logout", () => {
      logOut();
    });

    return () => {
      common.remove("logout");
    };
  }, [logOut]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="header-container">
      <Row className="header-row">
        <div>
          <Title level={3} className="header-title">
            GetUpp
          </Title>
        </div>
        <div className="menu-container">
          <Title level={5}>
            <Link to="/dashboard" className="menu-option">
              Dashboard
            </Link>
          </Title>
          <Title level={5}>
            <Link to="/notification-console" className="menu-option">
              Notification Console
            </Link>
          </Title>
          <Title level={5}>
            <Link to="/challenges" className="menu-option">
              Challenges
            </Link>
          </Title>
          <Title level={5}>
            <Link to="/qr-codes" className="menu-option">
              QR Codes
            </Link>
          </Title>
          <Title level={5}>
            <Link to="/users" className="menu-option">
              Users
            </Link>
          </Title>
          <Title level={5}>
            <Link to="/companies" className="menu-option">
              Company
            </Link>
          </Title>
          <Title level={5}>
            <Link to="/notification-dictionary" className="menu-option">
              Notification Dictionary
            </Link>
          </Title>
          <Title level={5}>
            <Link to="/auction" className="menu-option">
              Auction
            </Link>
          </Title>
          <div className="user-logout">
            <Title level={5}>Welcome, Admin</Title>
            <ButtonComponent
              title="Logout"
              onClick={() => {
                dispatch(logout());
              }}
            />
          </div>
        </div>
        <div className="menu-btn-container">
          <ButtonComponent icon={<MenuOutlined />} onClick={showDrawer} />
        </div>
      </Row>

      <Drawer
        className="mobile-drawer"
        width={200}
        title="Welcome, Admin"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={open}
      >
        <ul className="menu-options">
          <li>
            <Title level={5}>
              <Link to="/dashboard" className="menu-option">
                Dashboard
              </Link>
            </Title>
          </li>
          <li>
            <Title level={5}>
              <Link to="/notification-console" className="menu-option">
                Notification Console
              </Link>
            </Title>
          </li>
          <li>
            <Title level={5}>
              <Link to="/challenges" className="menu-option">
                Challenges
              </Link>
            </Title>
          </li>
          <li>
            <Title level={5}>
              <Link to="/qr-codes" className="menu-option">
                QR Codes
              </Link>
            </Title>
          </li>
          <li>
            <Title level={5}>
              <Link to="/users" className="menu-option">
                Users
              </Link>
            </Title>
          </li>
          <li>
            <Title level={5}>
              <Link to="/companies" className="menu-option">
                Company
              </Link>
            </Title>
          </li>
          <li>
            <Title level={5}>
              <Link to="/notification-dictionary" className="menu-option">
                Notification Dictionary
              </Link>
            </Title>
          </li>
          <li>
            <Title level={5}>
              <Link to="/auction" className="menu-option">
                Auction
              </Link>
            </Title>
          </li>
        </ul>
        <Title
          level={5}
          style={{ marginTop: "0.8em" }}
          onClick={() => {
            dispatch(logout());
          }}
        >
          Logout
        </Title>
      </Drawer>
    </div>
  );
}

export default Header;
