import { Typography, Row, Col, DatePicker, Button, Input, Popconfirm, Modal, Checkbox, Spin } from 'antd';
import InputField from '../../components/inputField/inputField';
import { ColumnsType } from "antd/lib/table";
import ButtonComponent from '../../components/button/button';
import TableComponent from "../../components/table/table";
import { columnTypes } from "../../models/componentTypes/types";
import './QRCodes.css';
import EditIcon from '../../assets/icons/edit.svg';
import { useEffect, useState } from 'react';
import ModalComponent from '../../components/modal/modal';
import RadioGroup from '../../components/radioGroup/radioGroup';
import SelectComponent from '../../components/select/select';
import QRCode from 'qrcode.react';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getQRDetails, saveQRDetails, updateQRDetails, searchCompanyName, deleteQrCode } from '../../features/QRCodes/QRCodes';
import moment from 'moment';
import { getAllUsers } from '../../features/common/common';
import Notification from '../../components/notification/notification';
import { getAllTemplates } from '../../features/notificationConsole/notificationConsole';
import { useNavigate } from 'react-router-dom';
import AutoCompleteComponent from '../../components/autoComplete/autoComplete';
import { DeleteOutlined } from "@ant-design/icons";
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';

const { Title } = Typography;
const Search = Input.Search;

const QRCodes = () => {
  const [formData, setFormData] = useState({
    companyId: null,
    companyName: '',
    deptName: '',
    SA: [],
    startDate: '',
    endDate: '',
    userLimit: '',
    membership: 0,
    selectedTemplate: ''
  });
  const [formErrors, setFormErrors] = useState({
    companyName: '',
    deptName: '',
    SA: '',
    startDate: '',
    endDate: '',
    userLimit: '',
  });
  const [isQRFormVisible, setIsQRFormVisible] = useState(false);
  const [usersArray, setUsersArray] = useState([]);
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [isQRGenerated, setQRGenerated] = useState(false);
  const QRDetails: columnTypes[] = useAppSelector((state) => state.QRCodes.QRDetails);
  const {token} = useAppSelector((state) => state.message);
  const [isEditMode, setEditMode] = useState(false);
  const [qrError, setQRError] = useState('');
  const [qrCode, setQRCode] = useState('');
  const [qrURL, setQRURL] = useState('');
  const dispatch = useAppDispatch();
  const { usersList, companies } = useAppSelector((state) => state.common);
  const { searchData, isLoading } = useAppSelector((state) => state.QRCodes);
  const { TemplateData } = useAppSelector((state) => state.notifications);
  const [templatesList, setTemplatesList] = useState([]);
  const [isQRCodeVisible, setIsQRCodeVisible] = useState(false);
  const [visibleQRURL, setVisibleQRURL] = useState('');
  const [currentEdit, setCurrentEdit] = useState({
    id: '',
    companyId: '',
    name: ''
  });
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [uploadResponse, setUploadResponse] = useState({status:'', successMessage:'', errors:[]});
  const [open, setOpen] = useState(false);
  const [QRData, setQRData] = useState<any>([]);
  const [companiesList, setCompaniesList] = useState<any>([]);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [cascadeDelete, setCascadeDelete] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getQRDetails());
    dispatch(getAllTemplates());
  }, [token]);

  useEffect(() => {
    setTemplatesList(TemplateData?.map((template: any) => {
      return {
        id: template.id,
        name: template.templateName
      }
    }));
  }, [TemplateData]);

  useEffect(() => {
    setUsersArray(usersList?.map((user: any) => {
      return {
        id: user.id,
        name: user.firstName + " " + user.lastName
      }
    }))
    // eslint-disable-next-line
  }, [usersList]);

  useEffect(() => {
    let element = document.getElementById('qrCodeEl') as HTMLCanvasElement;

    if(element !== null)
      setQRCode(element.toDataURL("image/png"));
  }, [qrURL]);

  useEffect(() => {
    if(isFormSubmitted)
    {
      let FD = {
        id: currentEdit.id,
        companyName: formData.companyName,
        departmentName: formData.deptName,
        userLimit: formData.userLimit,
        startDate: formData.startDate,
        endDate: formData.endDate,
        SAUserList: formData.SA,
        membership: formData.membership === 0 ? "Premium" : "Basic",
        QrCode: qrURL,
        Base64ImageStringGenerate: qrCode,
        ...(formData.selectedTemplate && { NotificationTemplateId: formData.selectedTemplate })
      };

      dispatch(updateQRDetails(FD)).then((response: any) => {
        setUploadResponse(response.payload);
        dispatch(getQRDetails());
        setFormSubmitted(false);
        setOpen(true);
      });
    }
  }, [qrCode, isFormSubmitted]);

  useEffect(() => {
    const filteredQRCodes = QRDetails?.filter(({ companyName }) => {
      companyName = companyName.toLowerCase();
      return companyName.includes(searchText.toLowerCase());
    });

    if(searchText === "")
      setQRData(QRDetails);
    else
      setQRData(filteredQRCodes);
      
  }, [QRDetails, searchText]);

  useEffect(() => {
    let newCompanyData = companies?.map((company: any, index: number) => {
      return { key: index, companyId: company.id, value: company.name }
    });
    setCompaniesList(newCompanyData);
  }, [companies]);

  function getRandomString() {
    let length = 8;
    let chars = '#A';
    let mask = '';
    if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
    if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (chars.indexOf('#') > -1) mask += '0123456789';
    if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
    var result = '';
    for (var i = length; i > 0; --i) result += mask[Math.round(Math.random() * (mask.length - 1))];
    return result;
  }

  let schema = yup.object().shape({
    companyName: yup.string().required("Company Name is required"),
    deptName: yup.string().test('Already-Exists','Department with this name already exists', function(deptName){
      let flag=true;

      searchData.forEach((department: any) => {
        if(department.name.toLowerCase() === deptName?.toLowerCase())
          flag=false;
      })

      return flag;
    }).required("Department Name is required"),
    SA: yup.array().required("Please select atleast one SA").min(1),
    startDate: yup.string().required("Start Date is required"),
    endDate: yup.string().required("End Date is required"),
    userLimit: yup.number().typeError("User Limit is required").min(1, "User Limit must be greater than or equal to 1").max(10000, "User Limit must be less than or equal to 10000").required("User Limit is required"),
  });

  let editSchema = yup.object().shape({
    companyName: yup.string().required("Company Name is required"),
    deptName: yup.string().required("Department Name is required"),
    SA: yup.array().required("Please select atleast one SA").min(1),
    startDate: yup.string().required("Start Date is required"),
    endDate: yup.string().required("End Date is required"),
    userLimit: yup.number().typeError("User Limit is required").min(1, "User Limit must be greater than or equal to 1").max(10000, "User Limit must be less than or equal to 10000").required("User Limit is required"),
  });

  let companyNameSchema = yup.object().shape({
    companyName: yup.string().required("Company Name is required")
  });

  let editCompanyNameSchema = yup.object().shape({
    companyName: yup.string().required("Company Name is required")
  });

  let deptNameSchema = yup.object().shape({
    deptName: yup.string().test('Already-Exists','Department with this name already exists', function(deptName){
      let flag=true;

      searchData.forEach((department: any) => {
        if(department.name.toLowerCase() === deptName?.toLowerCase())
          flag=false;
      })

      return flag;
    }).required("Department Name is required")
  });

  let editDeptNameSchema = yup.object().shape({
    deptName: yup.string().required("Department Name is required")
  });

  let SASchema = yup.object().shape({
    SA: yup.array().required("Please select atleast one SA").min(1)
  });

  let startDateSchema = yup.object().shape({
    startDate: yup.string().required("Start Date is required")
  });

  let endDateSchema = yup.object().shape({
    endDate: yup.string().required("End Date is required")
  });

  let userLimitSchema = yup.object().shape({
    userLimit: yup.number().typeError("User Limit is required").min(1, "User Limit must be greater than or equal to 1").max(10000, "User Limit must be less than or equal to 10000").required("User Limit is required")
  });

  const validateField = (schemaName: any, name: any, value: any) => {
    schemaName.validate({[name]: value}, {abortEarly: false})
    .then((response: any) => { setFormErrors({...formErrors, [name]: ""});})
    .catch((error: any) => {
      const errors = error.inner.reduce((acc: any, error: any) => {
        return {
          ...acc,
          [error.path]: error.errors[0],
        }
      }, {})
      setFormErrors({...formErrors, ...errors});
    })
  };

  const downloadQRCode = () => {
    let aEl = document.createElement("a");
    aEl.href = qrCode;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const handleInputChange = (name: any, value: any, record: any) => {
    let FD = {...formData, [name]: value};

    switch(name)
    {
      case 'companyId':
        FD = {...FD, companyName: record.value, companyId: record.companyId};
        break;
      case 'companyName':
        FD = {...FD, [name]: value, companyId: null};
        if(isEditMode)
          validateField(editCompanyNameSchema, name, value);
        else
          validateField(companyNameSchema, name, value);
        break;
      case 'deptName':
        dispatch(searchCompanyName(formData.companyName));
        validateField(deptNameSchema, name, value);
        break;
      case 'SA':
        FD = {...FD, 'SA': value};
        validateField(SASchema, name, value);
        setSelectAllUsers(false);
        break;
      case 'startDate': 
        validateField(startDateSchema, name, value);
        break;
      case 'endDate':
        validateField(endDateSchema, name, value);
        break;
      case 'userLimit': 
        validateField(userLimitSchema, name, value);
        break;
    }
    setFormData(FD);
  };

  const showQRForm = () => {
    setFormData({
      companyId: null,
      companyName: '',
      deptName: '',
      SA: [],
      startDate: '',
      endDate: '',
      userLimit: '',
      membership: 0,
      selectedTemplate: ''
    });
    setFormErrors({
      companyName: '',
      deptName: '',
      SA: '',
      startDate: '',
      endDate: '',
      userLimit: '',
    });
    setQRError('');
    setEditMode(false);
    setQRGenerated(false);
    setQRURL('');
    setQRCode('');
    setIsQRFormVisible(true);
  };

  const setQRField = async () => {
    let randomString = formData.companyName.replaceAll(' ','') + '-' + getRandomString();
    setQRURL(cleanupString(randomString));
    return qrURL;
  };  

  const cleanupString = (str: string): string => {
    return str
      // Convert Danish chars to ASCII
      .replace(/[æÆ]/g, 'ae')
      .replace(/[øØ]/g, 'oe')
      .replace(/[åÅ]/g, 'aa')
      // Remove spaces
      .replace(/\s+/g, '-')
      // Remove special chars & keep only alphanumeric + hyphens
      .replace(/[^a-zA-Z0-9-]/g, '')
      // Convert to lowercase
      // Clean multiple hyphens
      .replace(/-+/g, '-')
      // Remove leading/trailing hyphens
      .replace(/^-+|-+$/g, '')
      .toLocaleUpperCase();
  };

  const handleQRForm = async (e: any, schemaName: any) => {
    let FD = {};

    if(qrCode && qrURL)
      setQRError('');
    else
      setQRError("Please generate QR code.");

    schemaName.validate(formData, {abortEarly: false})
    .then(async (response: any) => { 
      if(isEditMode)
      {
        if(currentEdit.name !== formData.companyName && !isQRGenerated)
        {
          await setQRField().then(resp => {
            setFormSubmitted(true);
          });          
        }
        else
        {
          setFormSubmitted(true);
        }
        setIsQRFormVisible(false);
      }
      else
      {
        if(!qrURL || !qrCode)
        {
          setQRError("Please generate QR code.");
        }
        else
        {
          FD = {
            companyId: formData.companyId,
            companyName: formData.companyName,
            departmentName: formData.deptName,
            userLimit: formData.userLimit,
            startDate: formData.startDate,
            endDate: formData.endDate,
            SAUserList: formData.SA,
            membership: formData.membership === 0 ? "Premium" : "Basic",
            QrCode: qrURL,
            Base64ImageStringGenerate: qrCode,
            ...(formData.selectedTemplate && {NotificationTemplateId: formData.selectedTemplate})
          };

          dispatch(saveQRDetails(FD)).then((response: any) => {
            setUploadResponse(response.payload);
            dispatch(getQRDetails());
            setOpen(true);
          });

          setIsQRFormVisible(false);
        }
      }
    })
    .catch((error: any) => {
      const errors = error.inner.reduce((acc: any, error: any) => {
        return {
          ...acc,
          [error.path]: error.errors[0],
        }
      }, {});
      setFormErrors(errors);
    }); 
  };

  const handleQRFormCancel = () => {
    setIsQRFormVisible(false);
  };

  const handleSelectAllUsers = (e: any) => {
    setSelectAllUsers(e.target.checked);

    if(e.target.checked===true)
    {
      let allUsersList: any = usersArray.map((item: any) => {
        return item.id;
      })
      setFormData({...formData, SA: allUsersList})
    }
    else
    {
      setFormData({...formData, SA: []})
    }
  };

  const handleGenerateQRCode = (schemaName: any) => {
    schemaName.validate({deptName: formData.deptName}, {abortEarly: false})
    .then((response: any) => { 
      setQRError('');
      setQRGenerated(true);
      setQRURL(cleanupString(formData.companyName.replaceAll(' ','') + '-' + getRandomString()));
    })
    .catch((error: any) => {
      setQRError('Please enter valid department name.')
    })
  };

  const handleEditQRCode = (record: any) => {
    setEditMode(true);
    setCurrentEdit({id: record.id, companyId: record.companyId, name: record.companyName});
    
    setFormData({
      companyId: record.companyId,
      companyName: record.companyName,
      deptName: record.department,
      SA: record.saUserList,
      startDate: record.startDate,
      endDate: record.endDate,
      userLimit: record.userLimit,
      membership: record.membership === "Premium" ? 0 : 1,
      selectedTemplate: record.notificationTemplateId
    });

    setFormErrors({
      companyName: '',
      deptName: '',
      SA: '',
      startDate: '',
      endDate: '',
      userLimit: '',
    });
    setQRURL(record.qrCode);
    setQRError('');
    setQRGenerated(false);
    setIsQRFormVisible(true);
  };

  let handleQRCode = (record: any) => {
    setIsQRCodeVisible(!isQRCodeVisible);
    setVisibleQRURL("https://stayactivestatic.blob.core.windows.net" + record.qrCodeImageUrl);
  };

  let onSearch = (searchText: any) => {
    setSearchText(searchText);
  };

  const handleCompanySelect = (companyName: string, record: any) => {
    handleInputChange("companyId", '', record);
  };

  const handleDeleteQRCode = async () => {
    //dispatch deleteQrCode
    setConfirmLoading(true);
    const id = deleteId;
  
    try {
      await dispatch(deleteQrCode({ qrCodeId: id, cascadeDelete }));
      await dispatch(getQRDetails());
      setUploadResponse({
        status: "SUCCESS",
        successMessage: "QR Code deleted successfully.",
        errors: []
      });
      setOpen(true);
    } catch (error) {
      setUploadResponse({
        status: "ERROR",
        successMessage: "",
        errors: []
      });
      setOpen(true);
    } finally {
      
      setConfirmLoading(false);
      setModalOpen(false);
      setCascadeDelete(false);
      setDeleteId(0);
    }
  }

  const showDeleteConfirmModal = (id: number) => {
    setModalOpen(true);
    setDeleteId(id);
  }

    const columns: ColumnsType<columnTypes> = [
        {
          title: 'Company Name',
          dataIndex: 'companyName',
          key: 'companyName',
          onCell: (record, rowIndex) => {
            return {
              className: 'company-name-col',
              onClick: (ev) => navigate('/company', { state: {companyDetails: record} })
            }
          }
        },
        {
          title: 'Department',
          dataIndex: 'department',
          key: 'department'
        },
        {
          title: 'Membership',
          dataIndex: 'membership',
          key: 'membership',
          filters: [
            {
              text: 'Basic',
              value: 'Basic',
            },
            {
              text: 'Premium',
              value: 'Premium',
            }
          ],
          filterMode: 'tree',
          onFilter: (value: any, record: any) => record.membership.startsWith(value)
        },
        {
          title: 'Start Date',
          key: 'startDate',
          dataIndex: 'startDate',
          render: (startDate) => {
            return <span>{moment(startDate).format('DD-MM-YYYY')}</span>
          }
        },
        {
          title: 'End Date',
          key: 'endDate',
          dataIndex: 'endDate',
          render: (endDate) => {
            return <span>{moment(endDate).format('DD-MM-YYYY')}</span>
          }
        },
        {
          title: 'User Limit',
          key: 'userLimit',
          dataIndex: 'userLimit'
        },
        {
          title: 'Total Codes',
          key: 'totalCodes',
          dataIndex: 'totalCodes'
        },
        {
          title: 'Activated Codes',
          key: 'activatedCodes',
          dataIndex: 'activatedCodes'
        },
        {
          title: 'QR Code',
          key: 'QRCode',
          dataIndex: 'QRCode',
          render: (text, record, index) => {
            return (
              <span className="view-qr-code" onClick={() => handleQRCode(record)}>View QR Code</span>
            )
          },
        },
        {
          title: '',
          key: 'edit',
          dataIndex: 'edit',
          render: (text, record, index) => {
            return (
              <>
                <Row>
                  <Col>
                    <Button type='link' onClick={() => handleEditQRCode(record)}>
                      <img src={EditIcon} alt="Edit" className="edit-icon" onClick={() => handleEditQRCode(record)}/>
                    </Button>
                  </Col>
                  <Col>
                    <Button type='link' onClick={() => showDeleteConfirmModal(record.id)}>
                      <div className="delete-button">
                        <DeleteOutlined />
                      </div>
                    </Button>
                  </Col>
                </Row>
              </>
            )
          },
        },
      ];
      
    return(
      <>
        <div className="qr-container">
            <Row className="qr-header">
              <div>
                <Title level={2} style={{fontWeight:'700'}}>QR Codes</Title>
              </div>
              <div className="search-container">
                <Search
                  placeholder="Enter Company Name"
                  className="search-box"
                  onSearch={onSearch}
                  style={{ width: 200 }}
                />
                <ButtonComponent title="Add New QR Code" className="add-qr-button" onClick={showQRForm}/>
              </div>
            </Row>

            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{textAlign:'right'}}>
                <TableComponent Data={QRData} columns={columns} className="qr-table"/>
              </Col>
            </Row>

            <ModalComponent 
              title="View QR Code" 
              width={400} 
              closable={false}
              visible={isQRCodeVisible} 
              onOk={handleQRCode} 
              className="view-qr-container"
              footer={[
                <Button key="submit" type="primary" onClick={handleQRCode}>
                  Close
                </Button>
              ]}
            >
              <div className="qr-image-container">
                <img src={visibleQRURL} style={{height:'10em', width:'10em'}} alt="QR not found"/>
              </div>
              
            </ModalComponent>

            <ModalComponent 
              title={isEditMode ? "Edit QR Code" : "Add New QR Code"} 
              width={700} 
              closable={false}
              visible={isQRFormVisible} 
              onOk={(e) => handleQRForm(e, isEditMode ? editSchema : schema)} 
              onCancel={handleQRFormCancel}
              className="modal-container"
              footer={[
                <Button key="submit" type="primary" onClick={(e) => handleQRForm(e, isEditMode ? editSchema : schema)}>
                  {isEditMode ? "Save" : "Add New"}
                </Button>,
                <Button key="back" onClick={handleQRFormCancel}>
                  Cancel
                </Button>
              ]}
            >
              <Row gutter={[34,0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{position: 'relative'}}>
                  <Title level={5} className="input-label">Company Name</Title>
                  <AutoCompleteComponent options={companiesList} placeholder="Enter Company Name" value={formData.companyName} className={formErrors.companyName ? 'company-name nospace' : 'company-name'} onSelect={handleCompanySelect} onChange={(e) => handleInputChange("companyName", e, null)} disabled={isEditMode ? true : false} />
                  {/* <InputField placeholder="Ex. XYZ Private Ltd." value={formData.companyName} name="companyName" type="text" className={formErrors.companyName ? 'input-field nospace' : 'input-field'} onChange={(e) => handleInputChange(e.target.name, e.target.value)} disabled={isEditMode ? true : false}/> */}
                  {formErrors.companyName ? <Title level={5} className="error-label" type="danger">{formErrors.companyName}</Title> : ''}

                  <Title level={5} className="input-label">Department Name</Title>
                  <InputField placeholder="Ex. XYZ Department" value={formData.deptName} name="deptName" type="text" className={formErrors.deptName ? 'input-field nospace' : 'input-field'} onChange={(e) => handleInputChange(e.target.name, e.target.value, null)} disabled={isEditMode ? true : false} />
                  {formErrors.deptName ? <Title level={5} className="error-label" type="danger">{formErrors.deptName}</Title> : ''}

                  <Title level={5} className="input-label">SA</Title>
                  <SelectComponent mode="multiple" name="SA" className={formErrors.SA ? 'select-sa nospace' : 'select-sa'} placeholder="Select Users" allChecked={selectAllUsers} list={usersArray} selectedList={formData.SA} searchPlaceholder="Search and Select Users" onChange={(e) => handleInputChange("SA", e, null)} selectAll={true} onSelectAll={handleSelectAllUsers} isOptionGroup={false}/>
                  {formErrors.SA ? <Title level={5} className="error-label" type="danger">{formErrors.SA}</Title> : ''}

                  <Title level={5} className="input-label">Start Date</Title>
                  <DatePicker className={formErrors.startDate ? 'input-field nospace' : 'input-field'} value={formData.startDate ? moment(formData.startDate) : null} name="startDate" onChange={(e) => handleInputChange('startDate', e?.toISOString(), null)} getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement }/>
                  {formErrors.startDate ? <Title level={5} className="error-label" type="danger">{formErrors.startDate}</Title> : ''}

                  <Title level={5} className="input-label">End Date</Title>
                  <DatePicker className={formErrors.endDate ? 'input-field nospace' : 'input-field'} value={formData.endDate ? moment(formData.endDate) : null} name="endDate" onChange={(e) => handleInputChange('endDate', e?.toISOString(), null)} getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement } disabledDate={(current) =>{return moment(current)<moment(formData.startDate).add(30, "days")}} />
                  {formErrors.endDate ? <Title level={5} className="error-label" type="danger">{formErrors.endDate}</Title> : ''}

                  <Title level={5} className="input-label">User Limit</Title>
                  <InputField placeholder="1-10,000" value={formData.userLimit} name="userLimit" type="text" className="user-limit-input" onChange={(e) => handleInputChange('userLimit', e.target.value, null)}/>
                  <Title level={5} className="input-subtitle nospace">Limit Upto 1-10,000</Title>
                  {formErrors.userLimit ? <Title level={5} className="error-label nospace" type="danger">{formErrors.userLimit}</Title> : ''}

                  <Title level={5} className="input-label">Membership</Title>
                  <RadioGroup className="member-type" name="membership" selectedValue={formData.membership} direction="horizontal" onChange={(e: any) => handleInputChange('membership', e.target.value, null)} values={['Premium', 'Basic']} hasChild={false}/>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="generate-qr-container">
                    {
                      <QRCode
                      className={isQRGenerated ? 'qr-enabled' : 'qr-disabled'}
                      id="qrCodeEl"
                      size={200}
                      value={qrURL}
                      includeMargin={true}
                      /> 
                    }
                    {
                      qrError && <Title level={5} className="error-label" type="danger">{qrError}</Title>
                    }
                    <ButtonComponent title="Generate QR Code" className="generate-qr-btn" disabled={isEditMode ? true : false} onClick={() => handleGenerateQRCode(isEditMode ? editDeptNameSchema : deptNameSchema)}/>
                    {isQRGenerated && <a onClick={() => downloadQRCode()} style={{display:'block', margin:'2em 0em 0em 0em', textDecoration:'underline'}}>Download Now</a>}
                  </div>
                  <Title level={5} className="notification-template-label input-label">Notification Template</Title>
                  <SelectComponent className="notification-template-list" list={templatesList} hasSingleSelection={true} value={formData.selectedTemplate ? formData.selectedTemplate : undefined} placeholder="Select" onChange={(e) => handleInputChange('selectedTemplate', e, null)} selectAll={false} isOptionGroup={false}/>
                </Col>
              </Row>  
            </ModalComponent>
            
            <Notification 
              open={open} 
              setOpen={setOpen} 
              title={uploadResponse?.status === "SUCCESS" ? "Success" : "Error"} 
              message={uploadResponse?.successMessage ? uploadResponse.successMessage : uploadResponse?.errors ? uploadResponse.errors[0] : "Something went wrong, Please try again."} 
              severity={uploadResponse?.status === "SUCCESS" ? "success" : "error"} 
            />
            <Modal
              open={modalOpen}
              onCancel={() => setModalOpen(false)}
              onOk={handleDeleteQRCode}
              confirmLoading={confirmLoading}
              footer={[
                <Button key="close" disabled={confirmLoading} type="link" onClick={() => setModalOpen(false)}>
                  Cancel
                </Button>,
                <Button key="submit" disabled={confirmLoading} danger onClick={handleDeleteQRCode}>
                  Delete QR Code
                </Button>
              ]}
              >
              <Row>
                <Col>
                  <Title level={5} className="error-label" type="danger">
                     Delete QR Code
                  </Title>
                  <p>Are you sure you want to delete this QR Code?</p>
                  <p>
                    <Checkbox 
                      checked={cascadeDelete} 
                      onChange={() => setCascadeDelete(!cascadeDelete)}
                    >
                        Cascade delete to Company, Department, and Users
                    </Checkbox>
                  </p>
                </Col>
              </Row>
            </Modal>
            
        </div>
        { (isLoading) && <LoadingSpinner /> }
      </>
        
    )
}

export default QRCodes;