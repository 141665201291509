import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import userService from "../../services/userService";
import { setMessage } from '../message/message';

export interface AuctionDescription {
  name: string;
  description: string;
  language: string;
}

export interface AuctionProduct {
  id?: string;
  sponsor: string;
  startingBid: number;
  biddingStartDate: string;
  biddingEndDate: string;
  accessLevel: string;
  descriptions: AuctionDescription[];
  images: string[];
}

export interface AuctionListItem {
  id: string;
  name: string;
  description: string;
  language: string;
  startingBid: number;
  currentBid: number;
  biddingStart: string;
  biddingEnd: string;
  accessLevel: string;
  hasEnded: boolean;
}

export interface AuctionProductManage {
  id: string;
  sponsor: string;
  startingBid: number;
  biddingStartDate: string;
  biddingEndDate: string;
  createdDate: string;
  updateDate?: string;
  winnerUserId?: string;
  accessLevel: string;
  descriptions: AuctionDescription[];
  images: string[];
  sponsorLink?: string;
}

export interface AuctionCommandDto {
  sponsor: string;
  startingBid: number;
  biddingStartDate: string;
  biddingEndDate: string;
  accessLevel: string;
  descriptions: AuctionDescription[];
  images: string[];
}

export interface AuctionWinner {
  id: string;
  fullName: string;
  email: string;
  company: string;
  department: string;
  bidAmount: number;
}

interface AuctionState {
  products: AuctionListItem[];
  currentProduct: AuctionProductManage | null;
  isLoading: boolean;
  error: string | null;
  winner: AuctionWinner | null;
}

const initialState: AuctionState = {
  products: [],
  currentProduct: null,
  isLoading: false,
  error: null,
  winner: null,
};

export const getAuctionProducts = createAsyncThunk(
  "auction/getProducts",
  async (_, thunkAPI) => {
    try {
      const response = await userService.getAuctionProducts();
      return response.data.details;
    } catch (error: any) {
      return thunkAPI.dispatch(setMessage(error.response));
    }
  }
);

export const createAuctionProduct = createAsyncThunk(
  "auction/createProduct",
  async (data: Partial<AuctionProduct>, thunkAPI) => {
    try {
      const response = await userService.createAuctionProduct(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateAuctionProduct = createAsyncThunk(
  "auction/updateProduct",
  async ({ id, data }: { id: string; data: Partial<AuctionProduct> }, thunkAPI) => {
    try {
      const response = await userService.updateAuctionProduct(id, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await thunkAPI.dispatch(setMessage(error.response));
        const response = await userService.updateAuctionProduct(id, data);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  }
);

export const getAuctionProductById = createAsyncThunk(
  "auction/getProductById",
  async (productId: string, thunkAPI) => {
    try {
      const response = await userService.getAuctionProductById(productId);
      return response.data.details;
    } catch (error: any) {
      return thunkAPI.dispatch(setMessage(error.response));
    }
  }
);

export const getAuctionProductManage = createAsyncThunk(
  "auction/getProductManage",
  async (productId: string, thunkAPI) => {
    try {
      const response = await userService.getAuctionProductManageById(productId);
      return response.data.details;
    } catch (error: any) {
      return thunkAPI.dispatch(setMessage(error.response));
    }
  }
);

export const getAuctionWinner = createAsyncThunk(
  "auction/getWinner",
  async (productId: string, thunkAPI) => {
    try {
      const response = await userService.getAuctionWinner(productId);
      return response.data.details;
    } catch (error: any) {
      return thunkAPI.dispatch(setMessage(error.response));
    }
  }
);

export const deleteAuctionProduct = createAsyncThunk(
  "auction/deleteProduct",
  async (productId: string, thunkAPI) => {
    try {
      const response = await userService.deleteAuctionProduct(productId);
      return productId; // Return the ID to remove it from state
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await thunkAPI.dispatch(setMessage(error.response));
        const response = await userService.deleteAuctionProduct(productId);
        return productId;
      } else {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  }
);

const auctionSlice = createSlice({
  name: "auction",
  initialState,
  reducers: {
    clearCurrentProduct: (state) => {
      state.currentProduct = null;
    },
    clearWinner: (state) => {
      state.winner = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuctionProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAuctionProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.products = action.payload;
      })
      .addCase(getAuctionProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to fetch auction products";
      })
      .addCase(getAuctionProductById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAuctionProductById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentProduct = action.payload;
      })
      .addCase(getAuctionProductById.rejected, (state, action) => {

        state.isLoading = false;
        state.error = action.error.message || "Failed to fetch auction product";
      })
      .addCase(getAuctionProductManage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAuctionProductManage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentProduct = action.payload;
        state.error = null;
      })
      .addCase(getAuctionProductManage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to fetch auction product details";
      })
      .addCase(getAuctionWinner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAuctionWinner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.winner = action.payload;
        state.error = null;
      })
      .addCase(getAuctionWinner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to fetch auction winner";
      })
      .addCase(deleteAuctionProduct.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteAuctionProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.products = state.products.filter(product => product.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteAuctionProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to delete auction product";
      });
  },
});

export const { clearCurrentProduct, clearWinner } = auctionSlice.actions;

export default auctionSlice.reducer;
